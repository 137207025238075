import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "@mysten/dapp-kit/dist/index.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

// Screens
import ErrorPage from "./screens/error-page";
import ExecutorGpt from "./screens/executor-gpt";
import { SidebarProvider } from "./components/ui/sidebar";
import { ThemeProvider } from "./components/theme-provider";
import Home from "./screens/home";
import ProgramsPage from "./screens/programs";
import { useChat } from "./hooks/use-chat";
import { Toaster } from "./components/ui/toaster";

// Protected route wrapper
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { messages, chatId } = useChat();
  return messages?.length > 0 || chatId !== "" ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/chat",
        element: (
          <ProtectedRoute>
            <ExecutorGpt />
          </ProtectedRoute>
        ),
      },
      {
        path: "/programs",
        element: <ProgramsPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <Provider store={store}>
      <SidebarProvider>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <Toaster />
          <RouterProvider router={router} />
        </ThemeProvider>
      </SidebarProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
