import { useState } from "react";
import {
  InfoIcon,
  SlippageIcon,
  SwapRoute,
  PercentIcon,
} from "../../../assets/icons/flowbite-icons";
import { parseAmount } from "../../../utils/wallets";
import { Message, Submit, useChat } from "../../../hooks/use-chat";
import { useAppDispatch } from "../../../redux/hooks";
import { updateTokenConfetti } from "../../../redux/slices/user";
import { images } from "../../../assets/images";
import { useWallets } from "../../../hooks/wallets/use-wallets";
import { CardContent } from "../../ui/card";
import { Button } from "../../ui/button";
import { TokenCard } from "./token-card";

export const SwapQuote = ({
  data,
  setMessages,
  chatId,
  accessToken,
  active,
  onDone,
  submit,
}: {
  data: any;
  chatId: string;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  accessToken: string;
  active: boolean;
  onDone: () => void;
  submit: (data: Submit) => void;
}) => {
  const [signature, setSignature] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { addresses, signTransactions } = useWallets();
  const dispatch = useAppDispatch();
  const { allChains } = useChat();

  const onSwap = async () => {
    if (signature) return;
    try {
      setLoading(true);
      const signedTransaction = await signTransactions(data.transaction);
      const signedSignature =
        signedTransaction.hashes[signedTransaction.hashes.length - 1];
      setSignature(signedSignature);
      setLoading(false);
      onDone();
      submit({
        transaction: data,
        signature: signedSignature,
        walletAddress: addresses.SOLANA!,
        protocol_name: data.protocol_name,
        bridge_id: data.bridge_id ?? "",
      });

      dispatch(updateTokenConfetti(data.to_token.logoURI || images.SphereLogo));
    } catch (e: any) {
      console.error(e);
      setLoading(false);
      setMessages((m: Message[]) => [
        ...m,
        {
          agent: "ui",
          message: "",
          data: {
            status: "error",
            content: e.response?.data?.error ?? e.message,
          },
          component: "tx_status",
        },
      ]);
    } finally {
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <div className="bg-background border shadow-sm rounded-lg mt-4 flex flex-col items-center gap-4 max-w-[550px] mb-6">
        <div className="bg-gradient-to-t from-primary/5 to-primary/25 border-b border-secondary rounded-t-lg p-3 px-4 flex flex-col sm:flex-row justify-between items-center gap-4 w-full">
          {/* Input Token */}
          <TokenCard
            type={"From"}
            token={{
              logoURI: data.from_token.logoURI,
              symbol: data.from_token.symbol,
            }}
            chain={"SOLANA"}
            amount={formatDecimals(
              parseAmount(data.from_amount, data.from_token.decimals),
              6
            )}
            usdValue={Number(data?.from_token.usd_amount).toFixed(2) || 0}
            chainImage={
              allChains.find(
                (chain: { name: string; image: string }) =>
                  chain.name.toUpperCase() === "SOLANA"
              )?.image
            }
            defaultImage={images.DefaultCryptoLogo}
          />

          {/* Swap Icon */}
          <div className="flex items-center justify-center sm:rotate-90 rotate-0">
            <svg
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
              />
            </svg>
          </div>

          {/* Output Token */}
          <TokenCard
            type={"To"}
            token={{
              logoURI: data.to_token.logoURI,
              symbol: data.to_token.symbol,
            }}
            chain={"SOLANA"}
            amount={formatDecimals(
              parseAmount(data.to_amount, data.to_token.decimals),
              6
            )}
            usdValue={Number(data?.to_token.usd_amount).toFixed(2) || 0}
            chainImage={
              allChains.find(
                (chain: { name: string; image: string }) =>
                  chain.name.toUpperCase() === "SOLANA"
              )?.image
            }
            defaultImage={images.DefaultCryptoLogo}
          />
        </div>

        {/* Details */}
        <CardContent className="flex w-full p-4 pt-0 flex-col sm:gap-4 gap-2">
          <div className="flex flex-col items-center w-full h-103px gap-2">
            {Number(data?.total_fee_amount_usd) > 0 && (
              <div className="flex flex-row items-center px-1 w-full">
                <span className="text-gray-400 font-semibold text-sm leading-6">
                  Fees
                </span>
                <span className="text-gray-400 text-sm leading-6 ml-auto">
                  US${Number(data?.total_fee_amount_usd).toFixed(2)}
                </span>
              </div>
            )}
            <div className="flex flex-row items-center px-1 w-full">
              <InfoIcon />
              <span className="text-gray-400 font-semibold text-sm leading-6 ml-2">
                Total (USD)
              </span>
              <span className="text-gray-400 text-sm leading-6 ml-auto">
                $
                {Number(data?.from_token.usd_amount) +
                  Number(data?.total_fee_amount_usd ?? "0")}
              </span>
            </div>
          </div>

          <div className="flex flex-row items-center w-full">
            <SlippageIcon />
            <span className="text-gray-400 font-semibold text-sm leading-6 ml-2">
              Slippage Setting
            </span>
            <span className="text-gray-400 text-sm leading-6 ml-auto">
              {Number(data?.slippage / 100).toFixed(2)}%
            </span>
          </div>

          <div className="flex flex-row w-full">
            <PercentIcon />
            <span className="text-gray-400 font-semibold text-sm leading-6 ml-2">
              Price Impact
            </span>
            <span className="text-gray-400 text-sm leading-6 ml-auto">
              {Number(data?.price_impact) < 0.1
                ? "<0.1%"
                : `${Number(data?.price_impact).toFixed(2)}%`}
            </span>
          </div>

          {data.used_providers && (
            <div className="flex flex-row justify-start items-center w-full">
              <SwapRoute />
              <span className="text-gray-400 font-semibold text-sm leading-6 ml-2">
                Via
              </span>
              <span className="text-gray-400 text-sm leading-6 ml-2">
                {data.used_providers}
              </span>
            </div>
          )}
          <div className="flex justify-start w-full">
            <Button
              onClick={onSwap}
              className={`w-full ${loading ? "cursor-not-allowed animate-pulse" : ""}`}
              disabled={loading || !!signature}
            >
              {!loading ? "Swap" : "Waiting for signature..."}
            </Button>
          </div>
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-gray-400 font-semibold text-xs leading-6 ml-auto">
              AI Agent:
            </span>
            <span className="text-gray-400 text-xs leading-6 ml-2">
              {data.agent}
            </span>
          </div>
        </CardContent>
      </div>
    </>
  );
};

function formatDecimals(number: number | string, maxDecimals: number) {
  if (!number) return "0.00";
  else if (typeof number === "string") {
    return Number.parseFloat(number).toFixed(maxDecimals).toString();
  } else {
    return Number.parseFloat(number.toFixed(maxDecimals)).toString();
  }
}
