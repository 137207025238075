"use client";

import { useState, useMemo } from "react";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader } from "./ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { CreditCard, Download, Wallet } from "lucide-react";
import { useWallets } from "../hooks/wallets/use-wallets";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ScrollArea } from "./ui/scroll-area";
import { images } from "../assets/images";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useChat } from "../hooks/use-chat";
import { Skeleton } from "./ui/skeleton";
import { selectPreferredAgentThunk } from "../redux/slices/gptThunk";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "./ui/accordion";
import { NFTCard } from "./chat/message-actions/nfts";
import { selectedWalletAddress } from "../redux/slices/user";
import ConnectWalletButton, {
  walletTypeToProviders,
} from "./connect-wallet-button";

const Image = ({
  image,
  defaultImage,
}: {
  image: any;
  defaultImage: string;
}) => {
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultImage;
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2 w-12">
      <img
        src={image?.logo_uri}
        alt={image?.name || "Crypto Logo"}
        onError={handleImageError}
        className="h-10 w-10 rounded-full"
      />
    </div>
  );
};

export default function WalletPopUp({
  setShowBalances,
  chatStarted,
}: {
  setShowBalances: (show: boolean) => void;
  chatStarted: boolean;
}) {
  const { appendMessage, superAgent, setMessages, loading, allChains } =
    useChat();
  const dispatch = useAppDispatch();

  const { addresses } = useWallets();
  const [selectedAddress, setSelectedAddress] = useState("all");
  const { balances, isLoading, nfts } = useAppSelector((state) => state.user);
  const { preferredAgent } = useAppSelector((state) => state.gpt);

  // Filter balances according to the minimum and the selected wallet
  const filteredBalances = useMemo(() => {
    if (!balances) return [];

    return balances.filter((balance) => {
      const hasMinimumBalance = balance.usd_amount && balance.usd_amount > 0.01;
      const isAddressMatch =
        selectedAddress === "all" || balance.wallet_address === selectedAddress;
      return hasMinimumBalance && isAddressMatch;
    });
  }, [balances, selectedAddress]);

  // Group balances by token
  const groupedBalances = useMemo(() => {
    const balanceMap: { [tokenName: string]: any[] } = {};

    filteredBalances.forEach((balance) => {
      if (!balanceMap[balance.name]) {
        balanceMap[balance.name] = [];
      }
      balanceMap[balance.name].push(balance);
    });

    return balanceMap;
  }, [filteredBalances]);

  const handleAddressSelect = (value: string) => {
    setSelectedAddress(value);
    if (value !== "all") {
      dispatch(selectedWalletAddress(value));
    }
  };

  const connectedWallets = walletTypeToProviders.filter(
    ({ name }) => addresses[name] !== null
  );

  return (
    <Card className="flex flex-col sm:w-[400px] w-full fixed top-0 right-0 sm:top-4 sm:right-4 shadow-xl border-2">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
        <div className="flex items-center gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <ConnectWalletButton chatStarted={chatStarted} />
              </TooltipTrigger>
              <TooltipContent>Connect Wallet</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <div>
            <Select value={selectedAddress} onValueChange={handleAddressSelect}>
              <SelectTrigger className="w-[180px] h-10">
                <SelectValue
                  placeholder={
                    selectedAddress === "all"
                      ? "All Wallets..."
                      : `${selectedAddress.slice(0, 6)}...${selectedAddress.slice(-6)}`
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Wallets...</SelectItem>
                {Object.values(addresses).map((address) =>
                  address ? (
                    <SelectItem key={address} value={address}>
                      <div className="flex flex-row-reverse items-center gap-2">
                        {address.slice(0, 6)}...{address.slice(-6)}
                        {connectedWallets
                          .filter(({ name }) => addresses[name] === address)
                          .map(({ name, iconSrc }) => (
                            <img
                              key={name}
                              src={require(`../assets/images/${iconSrc}.png`)}
                              alt={`${name} Icon`}
                              className="w-6 h-6 rounded-full border border-gray-300"
                            />
                          ))}
                      </div>
                    </SelectItem>
                  ) : null
                )}
              </SelectContent>
            </Select>
          </div>
        </div>

        <Button
          variant="ghost"
          size="icon"
          onClick={() => setShowBalances(false)}
        >
          <XMarkIcon className="h-5 w-5 text-foreground" />
        </Button>
      </CardHeader>

      <CardContent className="space-y-6">
        {/* Total Balance */}
        <div>
          {isLoading ? (
            <Skeleton className="h-10 w-full" />
          ) : (
            <div className="text-3xl font-bold">
              $
              {Object.values(groupedBalances)
                .flat()
                .reduce((acc, token) => acc + token.usd_amount!, 0)
                .toFixed(2)}
            </div>
          )}
        </div>

        {/* Buy and Receive Buttons */}
        <div className="grid grid-cols-2 gap-4">
          <Button
            onClick={async () => {
              if (loading) return;
              if (preferredAgent !== superAgent) {
                setMessages([]);
              }
              await dispatch(selectPreferredAgentThunk(superAgent)).unwrap();
              await appendMessage("I want to buy some crypto", superAgent);
            }}
            className="bg-primary/10 hover:bg-primary/20 text-primary"
          >
            <CreditCard className="mr-2 h-4 w-4" />
            Buy
          </Button>
          <Button
            onClick={async () => {
              if (loading) return;
              if (preferredAgent !== superAgent) {
                setMessages([]);
              }
              await dispatch(selectPreferredAgentThunk(superAgent));
              await appendMessage(`I want to receive some crypto`, superAgent);
            }}
            className="bg-primary/10 hover:bg-primary/20 text-primary"
          >
            <Download className="mr-2 h-4 w-4" />
            Receive
          </Button>
        </div>

        {/* Tabs */}
        <Tabs defaultValue="tokens" className="w-full">
          {/* Tabs Header */}
          <TabsList className="grid w-full grid-cols-4">
            <TabsTrigger value="tokens">Tokens</TabsTrigger>
            <TabsTrigger value="nfts">NFTs</TabsTrigger>

            {/* Pools Tab */}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  {" "}
                  <TabsTrigger disabled value="pools">
                    Pools
                  </TabsTrigger>
                </TooltipTrigger>
                <TooltipContent>Coming Soon</TooltipContent>
              </Tooltip>
            </TooltipProvider>

            {/* Activity Tab */}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <TabsTrigger disabled value="activity">
                    Activity
                  </TabsTrigger>
                </TooltipTrigger>
                <TooltipContent>Coming Soon</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </TabsList>
          {/* Tabs Content */}
          <ScrollArea className="h-[550px]">
            <TabsContent
              value="tokens"
              className={`${!isLoading ? "mr-6" : ""} space-y-4`}
            >
              <Accordion type="single" collapsible>
                {isLoading ? (
                  <div className="space-y-2 w-full">
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                  </div>
                ) : (
                  Object.keys(groupedBalances).map((tokenName) => {
                    // Temporal patch for the token image for USDC
                    const tokenImage =
                      tokenName === "USD Coin"
                        ? {
                            logo_uri:
                              "https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694",
                          }
                        : groupedBalances[tokenName][0];
                    return (
                      <AccordionItem key={tokenName} value={tokenName}>
                        <AccordionTrigger>
                          <div className="flex items-center justify-between w-full">
                            <div className="flex items-center gap-2">
                              <Image
                                image={tokenImage}
                                defaultImage={images.DefaultCryptoLogo}
                              />
                              <span className="font-medium">{tokenName}</span>
                            </div>
                            <span className="font-medium text-right">
                              $
                              {groupedBalances[tokenName]
                                .reduce(
                                  (acc, token) => acc + token.usd_amount!,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          {groupedBalances[tokenName].map((token, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between px-4 py-2"
                            >
                              <div className="flex items-center gap-3">
                                <img
                                  src={
                                    allChains.find(
                                      (chain: {
                                        name: string;
                                        image: string;
                                      }) => chain.name === token.chain
                                    )?.image || images.DefaultCryptoLogo
                                  }
                                  alt={token.chain}
                                  className="h-5 w-5 rounded-full"
                                />
                                <span className="text-sm font-medium">
                                  {token.chain}
                                </span>
                              </div>
                              <div className="text-sm font-medium flex flex-col items-end ">
                                <span>${token.usd_amount?.toFixed(2)}</span>
                                <span>{token.amount?.toFixed(6)}</span>
                              </div>
                            </div>
                          ))}
                        </AccordionContent>
                      </AccordionItem>
                    );
                  })
                )}
              </Accordion>
            </TabsContent>

            <TabsContent value="nfts">
              {nfts && nfts.length > 0 ? (
                <div className="grid gap-4 p-4 grid-cols-2">
                  {nfts.map((nft, index) => (
                    <NFTCard
                      nft={nft}
                      index={index}
                      delay={index * 100}
                      key={nft.id}
                    />
                  ))}
                </div>
              ) : nfts === null ? (
                <Button
                  className="w-full"
                  disabled={loading}
                  onClick={async () => {
                    if (preferredAgent !== superAgent) {
                      setMessages([]);
                    }
                    await dispatch(
                      selectPreferredAgentThunk(superAgent)
                    ).unwrap();
                    await appendMessage(
                      "Get all of my nfts on all the supported chains, please.",
                      superAgent
                    );
                  }}
                >
                  Call NFT Agent
                </Button>
              ) : (
                <div className="flex h-full w-full items-center justify-center">
                  No NFTs found
                </div>
              )}
            </TabsContent>
          </ScrollArea>
        </Tabs>
      </CardContent>
    </Card>
  );
}
