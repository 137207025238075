import { WalletType, useWallets } from "../hooks/wallets/use-wallets";
import { WalletProvider } from "../hooks/wallets/providers/wallet-provider";
import { clearWalletBalances, setIsLoading } from "../redux/slices/user";
import { useAppDispatch } from "../redux/hooks";
import { useToast } from "../hooks/use-toast";
// import { requestFCMPermission } from "../utils/firebase_cloud_messaging";

import { Button } from "../components/ui/button";
import { Checkbox } from "../components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import { ScrollArea } from "../components/ui/scroll-area";
import { ThemedButton } from "./themed/ThemedButton";
import { LogOut, Copy, Wallet } from "lucide-react";
import { useEffect, useState } from "react";

function shortenAddress(address: string) {
  return address.slice(0, 6) + "..." + address.slice(-6);
}

export const walletTypeToProviders = [
  { name: WalletType.EVM, iconSrc: "EvmIcon", options: [WalletProvider.EVM] },
  {
    name: WalletType.APTOS,
    iconSrc: "AptosIcon",
    options: [WalletProvider.APTOS],
  },
  {
    name: WalletType.COSMOS,
    iconSrc: "CosmosIcon",
    options: [WalletProvider.COSMOS],
  },
  {
    name: WalletType.SOLANA,
    iconSrc: "SolanaIcon",
    options: [WalletProvider.PHANTOM, WalletProvider.TIPLINK],
  },
  { name: WalletType.SUI, iconSrc: "SuiIcon", options: [WalletProvider.SUI] },
  {
    name: WalletType.BITCOIN,
    iconSrc: "BitcoinIcon",
    options: [WalletProvider.BITCOIN],
  },
];

export default function ConnectWalletButton(props: {
  className?: string;
  chatStarted?: boolean;
}) {
  const {
    addresses,
    connect,
    disconnect,
    loading,
    agentsPreference,
    setAgentsPreference,
  } = useWallets();

  const dispatch = useAppDispatch();
  const { toast } = useToast();
  // const [hasRequestedFCM, setHasRequestedFCM] = useState(false);

  // useEffect(() => {
  //   if (addresses.EVM && !hasRequestedFCM) {
  //     requestFCMPermission(addresses.EVM as string);
  //     setHasRequestedFCM(true);
  //   }
  // }, [addresses.EVM]);

  const handleConnect = async (
    walletType: WalletType,
    provider: WalletProvider
  ) => {
    try {
      if (addresses[walletType] === null) {
        await connect(walletType, provider);
      }
    } catch (error) {
      console.error("Error connecting wallet: ", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleDisconnect = (walletType: WalletType) => {
    const addressToRemove = addresses[walletType] as string;
    dispatch(clearWalletBalances(addressToRemove));
    disconnect(walletType);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Wallet address copied!",
      });
    });
  };

  return (
    <div className="flex gap-2">
      <Dialog>
        <DialogTrigger>
          <Button className="w-full sm:text-sm text-xs p-2" variant="outline">
            {Object.values(addresses).filter((w) => w !== null).length > 0 ? (
              <>
                <span className="text-center text-xs items-center justify-center">
                  ({Object.values(addresses).filter((w) => w !== null).length})
                </span>
                <Wallet className="h-6 w-6" />
              </>
            ) : (
              "Connect Wallet"
            )}
          </Button>
        </DialogTrigger>
        <DialogContent className="bg-background text-foreground sm:max-w-[425px]">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-2xl font-bold">
              Connect Wallet
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] pr-4">
            <>
              {walletTypeToProviders.map((wallet) => (
                <div key={wallet.name} className="mb-4">
                  {addresses[wallet.name] ? (
                    <Button
                      variant="outline"
                      className="bg-background text-foreground w-full justify-between mb-2 text-left flex items-center"
                      onClick={async () => {
                        await handleConnect(wallet.name, wallet.options[0]);
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={require(
                            `../assets/images/${wallet.iconSrc}.png`
                          )}
                          alt={`${wallet.name} Icon`}
                          className="mr-2 h-6 w-6 dark:bg-gray-200 rounded-full"
                        />
                        {shortenAddress(addresses[wallet.name] as string)}
                      </div>
                      <div className="flex items-center">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(addresses[wallet.name] as string);
                          }}
                          className="mr-2"
                        >
                          <Copy className="h-4 w-4" />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDisconnect(wallet.name);
                          }}
                        >
                          <LogOut className="h-4 w-4" />
                        </button>
                      </div>
                    </Button>
                  ) : (
                    wallet.options.map((option) => (
                      <Button
                        key={option}
                        variant="outline"
                        className="bg-background text-foreground w-full justify-between mb-2 text-left flex items-center"
                        onClick={async () => {
                          await handleConnect(wallet.name, option);
                        }}
                      >
                        <div className="flex items-center">
                          <img
                            src={require(
                              `../assets/images/${wallet.iconSrc}.png`
                            )}
                            alt={`${wallet.name} Icon`}
                            className="mr-2 h-6 w-6 dark:bg-gray-200 rounded-full"
                          />
                          {loading[wallet.name] ? (
                            <p>Connecting wallet...</p>
                          ) : (
                            option.charAt(0).toUpperCase() + option.slice(1)
                          )}
                        </div>
                      </Button>
                    ))
                  )}
                </div>
              ))}
            </>
          </ScrollArea>
          {/* <div className="flex flex-col items-center space-x-2 mt-2">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="agentWallets"
                checked={agentsPreference}
                disabled={props.chatStarted}
                onCheckedChange={() => setAgentsPreference(!agentsPreference)}
              />
              <label
                htmlFor="agentWallets"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Use agents wallets for more flexibility and power
              </label>
            </div>
            {agentsPreference && (
              <div className="mt-4">
                <p className="text-foreground text-sm text-justify">
                  Agent wallets are managed by the agents. So you can confirm
                  transactions with natural language and ask more complex tasks
                  without much involvement. To get started you'll need to add
                  funds to them. You can check your address right above.
                </p>
              </div>
            )}
          </div> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
