import {
  Home,
  Users,
  FileText,
  Settings,
  History,
  MessageCircle,
  Trash,
  PanelRightOpen,
  PanelLeftOpenIcon,
  Info,
  Clock,
  ChevronDown,
  GraduationCap,
  BookOpen,
} from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  useSidebar,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarSeparator,
} from "../components/ui/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeModeToggle } from "./toggle-theme";
import { images } from "../assets/images";
import { useTheme } from "./theme-provider";
import SettingsPage from "../screens/settings";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTrigger,
} from "./ui/dialog";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { useChat, Message } from "../hooks/use-chat";
import { setCurrentChatId, deleteChat } from "../redux/slices/user";
import { serverApi } from "../services/server";
import { Button } from "./ui/button";
import { BsDiscord, BsTwitterX } from "react-icons/bs";
import { ScrollArea } from "./ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useEffect, useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";

import { ChatHistory } from "../redux/slices/user";
import { selectPreferredAgentThunk } from "../redux/slices/gptThunk";
import { AgentProps } from "./agent-functionality";

interface ChatHistoryMessages {
  messages: Message[];
  lastUpdatedAt: string;
}

const LP_SPECIALIST_DESCRIPTION =
  "Our first program, the LP Specialist will help you optimize liquidity positions with real-time insights and automation, saving you time and maximizing returns (demo soon!).";

interface ChatHistoryMessages {
  messages: Message[];
  lastUpdatedAt: string;
}

export default function SideBar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();
  const { preferredAgent } = useAppSelector((state) => state.gpt);
  const { chatHistory, currentChatId } = useAppSelector((state) => state.user);
  const { setMessages, restoreOrSetNewChat, loading, launchedPrograms } =
    useChat();
  const [todayChats, setTodayChats] = useState<ChatHistory>();
  const [previousChats, setPreviousChats] = useState<ChatHistory>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toggleSidebar, state } = useSidebar();

  // Determine the active tab based on the current path
  const getActiveTab = () => {
    if (location.pathname === "/") return "Home";
    if (location.pathname === "/programs") return "Programs";
    if (location.pathname === "/chat") return "Chat";
    if (location.pathname === "/chat-history") return "Chat History";
    return "";
  };

  const activeTab = getActiveTab();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const restoreChat = async (
    ChatHistoryMessages: ChatHistoryMessages,
    chatId: string
  ) => {
    if (loading) {
      return;
    }
    await dispatch(selectPreferredAgentThunk(null)).unwrap();
    setMessages([]);
    const chatMessages = ChatHistoryMessages.messages;
    chatMessages?.forEach((message) => {
      if (message.component !== null) {
        setMessages((m: Message[]) => [
          ...m,
          {
            agent: "ui",
            message: "",
            component: message.component,
            data: message.data,
          },
        ]);
      } else if (message.agent === "human") {
        setMessages((m: string) => [
          ...m,
          { agent: "human", message: message.message },
        ]);
      } else if (message.message !== "") {
        setMessages((m: string) => [
          ...m,
          { agent: "ai", message: message.message },
        ]);
      }
    });

    // Get the last 5 messages to avoid exceeding autogen tokens limit
    const lastFiveMessages = chatMessages?.slice(-5);
    const stringifiedMessages = JSON.stringify(lastFiveMessages);

    restoreOrSetNewChat(chatId, stringifiedMessages);
    dispatch(setCurrentChatId(chatId));
    navigate("/chat");
  };

  useEffect(() => {
    if (chatHistory) {
      const todayChats = Object.keys(chatHistory).filter((chatId) => {
        const lastUpdatedAt = new Date(chatHistory[chatId].lastUpdatedAt);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to midnight
        return lastUpdatedAt >= today;
      });

      setTodayChats(
        todayChats.reduce((acc, chatId) => {
          acc[chatId] = chatHistory[chatId]; // Assign the full chat object
          return acc;
        }, {} as ChatHistory)
      );

      const previousChats = Object.keys(chatHistory).filter(
        (chatId) => !todayChats.includes(chatId)
      );

      setPreviousChats(
        previousChats.reduce((acc, chatId) => {
          acc[chatId] = chatHistory[chatId]; // Assign the full chat object
          return acc;
        }, {} as ChatHistory)
      );
    }
  }, [chatHistory]);

  const handleDeleteChat = async (chatId: string, walletAddress: string) => {
    try {
      dispatch(deleteChat(chatId));
      if (currentChatId === chatId) {
        // If its the current chat, clear the messages and reset the current chat id
        setMessages([]);
        dispatch(setCurrentChatId(""));
        restoreOrSetNewChat(null, null);
      }
      await serverApi.deleteChatFromHistory(chatId, walletAddress);
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const handleProgramClick = async (program: AgentProps) => {
    if (loading) {
      return;
    }
    if (program.name !== preferredAgent?.name) {
      dispatch(setCurrentChatId(null));
      restoreOrSetNewChat(null, null);
      setMessages([]);
    }
    await dispatch(selectPreferredAgentThunk(program)).unwrap();
    navigate("/chat");
  };

  return (
    <Sidebar className="border-r" collapsible="offcanvas">
      {/* Sidebar Header */}
      <SidebarHeader className="border-b">
        <SidebarMenu>
          <SidebarMenuItem>
            {/* Orbit Logo */}
            <SidebarMenuButton size="lg" asChild>
              <div className="flex items-center gap-2 cursor-pointer justify-center place-items-center align-middle content-center">
                <p
                  className="flex w-full items-center gap-2 cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <img
                    src={images.OrbitStanding}
                    alt="Orbit"
                    className="h-8 w-8"
                  />
                  <span className="text-xl font-bold">Orbit</span>
                </p>
                <Button
                  variant="ghost"
                  className="flex items-center justify-center place-items-center"
                  onClick={toggleSidebar}
                >
                  {state === "expanded" ? (
                    <PanelRightOpen className="h-4 w-4" />
                  ) : (
                    <PanelLeftOpenIcon className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </SidebarMenuButton>
            {/* Search Bar */}
            {/* <div className="mt-2 flex items-center gap-2">
              <SearchBarOverlay />
            </div> */}
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>

      {/* Sidebar Main Content */}
      <ScrollArea className="flex-1">
        <SidebarContent>
          {/* Home and Agents Section */}
          <SidebarGroup className="flex flex-col gap-2">
            {/* Home */}
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  className={`transition-all transform duration-300 ease-in-out ${activeTab === "Home" ? "ml-1" : ""}`}
                >
                  <p
                    className={`flex items-center gap-2 cursor-pointer ${
                      activeTab === "Home" ? "bg-gray-200 dark:bg-gray-800" : ""
                    }`}
                    onClick={() => navigate("/")}
                  >
                    <Home className="h-4 w-4" />
                    <span>Home</span>
                  </p>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>

            {/* Agents */}
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  className={`transition-all transform duration-300 ease-in-out ${activeTab === "Programs" ? "ml-1" : ""}`}
                >
                  <p
                    className={`flex items-center gap-2 cursor-pointer ${
                      activeTab === "Programs"
                        ? "bg-gray-200 dark:bg-gray-800"
                        : ""
                    }`}
                    onClick={() => navigate("/programs")}
                  >
                    <Users className="h-4 w-4" />
                    <span>Programs</span>
                  </p>
                </SidebarMenuButton>
              </SidebarMenuItem>

              {/* Favourite Agents Section */}
              {/* {selectedWalletAddress && (
                <SidebarGroup>
                  <SidebarGroupLabel className="flex items-center justify-between px-2">
                    <span className="flex flex-col">
                      Favourite Agents{" "}
                      {agents && agents.length > 0 ? `${agents.length}/5` : ""}{" "}
                      <br />
                      <span className="my-1">
                        {shortenAddress(selectedWalletAddress)}
                      </span>
                    </span>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-5 w-5"
                      onClick={() => navigate("/programs")}
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </SidebarGroupLabel>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      {agents?.map((agent) => (
                        <SidebarMenuItem key={agent.name}>
                          <SidebarMenuButton
                            asChild
                            className="cursor-pointer"
                            onClick={async () => {
                              await dispatch(
                                selectPreferredAgentThunk(agent)
                              ).unwrap();
                              navigate("/chat");
                            }}
                          >
                            <div className="flex items-center justify-between gap-2">
                              <p className="flex items-center gap-2">
                                <Avatar className="h-8 w-8 rounded-md">
                                  <AvatarImage
                                    className="p-1 bg-primary/5 dark:bg-gray-700"
                                    src={agent.img}
                                    alt={agent.name}
                                  />
                                  <AvatarFallback>
                                    {agent.name[0]}
                                  </AvatarFallback>
                                </Avatar>
                                <span>{agent.name}</span>
                              </p>

                              <Trash
                                className="opacity-30 hover:opacity-100 h-4 w-4 hover:text-gray-500 hover:fill-gray-500 cursor-pointer transition-all duration-300 ease-in-out"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(saveAgent(agent));
                                }}
                              />
                            </div>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      ))}
                    </SidebarMenu>
                  </SidebarGroupContent>
                </SidebarGroup>
              )} */}
            </SidebarMenu>

            {/* New Programs Section */}
            <SidebarMenu>
              {launchedPrograms.map((program: AgentProps) => {
                let icon;
                // TODO - Update this to get the icon without having to do this here.
                if (program.name === "Educational Program") {
                  icon = <GraduationCap className="h-4 w-4" />;
                } else if (program.name === "Researcher Program") {
                  icon = <BookOpen className="h-4 w-4" />;
                } else {
                  icon = (
                    <img
                      src={program.img}
                      alt={program.name}
                      className="h-4 w-4"
                    />
                  );
                }
                return (
                  <SidebarMenuItem key={program.name}>
                    <SidebarMenuButton
                      className={`transition-all transform duration-300 ease-in-out ${activeTab === "Chat" && program.name === preferredAgent?.name ? "ml-1 bg-gray-200 dark:bg-gray-800" : ""} 
flex items-center gap-2 cursor-pointer `}
                    >
                      <p
                        className={`flex items-center gap-2 cursor-pointer`}
                        onClick={() => handleProgramClick(program)}
                      >
                        {icon}
                        <span>{program.name}</span>
                        <span className="text-xs text-gray-500 dark:text-gray-400 italic">
                          new 🔥
                        </span>
                      </p>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                );
              })}
            </SidebarMenu>

            {/* LP Specialist Section */}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <SidebarGroup>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="flex w-full items-center"
                      onClick={handleOpenModal}
                    >
                      <SidebarGroupLabel className="flex w-full items-center justify-between">
                        <p className="flex text-start dark:text-gray-200">
                          LP Specialist
                          <span className="text-[10px] ml-2 text-gray-500 dark:text-gray-400 italic">
                            Coming Soon
                          </span>
                        </p>
                        <Info className="h-4 w-4" />
                      </SidebarGroupLabel>
                    </Button>
                    <SidebarGroupContent>
                      <SidebarMenu>{/* LP Specialist Content */}</SidebarMenu>
                    </SidebarGroupContent>
                  </SidebarGroup>
                </TooltipTrigger>
                <TooltipContent className="max-w-[300px] text-xs text-gray-700 dark:text-gray-200 ml-2">
                  {LP_SPECIALIST_DESCRIPTION}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            {/* LP Specialist Modal */}
            <Dialog open={isModalOpen} onOpenChange={handleCloseModal}>
              <DialogContent>
                <DialogHeader className="mb-2">
                  <p className="dark:text-gray-200">
                    LP Specialist{" "}
                    <span className="text-xs text-gray-500 dark:text-gray-400 italic">
                      Coming Soon
                    </span>
                  </p>
                </DialogHeader>
                <DialogDescription>
                  <p className="text-sm justify-start text-start dark:text-gray-200">
                    {LP_SPECIALIST_DESCRIPTION}
                  </p>
                </DialogDescription>
              </DialogContent>
            </Dialog>
          </SidebarGroup>

          <SidebarSeparator />

          {/* Chat History Section */}
          <SidebarGroup>
            <SidebarGroupLabel>
              <p className="flex items-center gap-2 dark:text-gray-200">
                <Clock className="h-4 w-4" />
                Chat History
              </p>
            </SidebarGroupLabel>
            <SidebarGroupContent className="flex flex-col gap-4">
              <Collapsible defaultOpen>
                <CollapsibleTrigger className="flex w-full items-center justify-between px-2 py-1 text-sm text-muted-foreground hover:text-foreground">
                  Today
                  <ChevronDown className="h-4 w-4" />
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenu>
                    {todayChats &&
                      Object.entries(todayChats).map(([chatId, messages]) => (
                        <SidebarMenuItem
                          key={chatId}
                          className={`flex flex-row items-center justify-between px-2 rounded-md transition-all}
                  `}
                        >
                          <SidebarMenuButton
                            asChild
                            disabled={loading}
                            onClick={() =>
                              restoreChat(
                                messages as ChatHistoryMessages,
                                chatId
                              )
                            }
                          >
                            <p className="text-sm">
                              {messages.messages[0].message.slice(0, 20)}...
                            </p>
                          </SidebarMenuButton>
                          <button
                            disabled={loading}
                            onClick={() =>
                              handleDeleteChat(
                                chatId,
                                messages.associated_wallet!
                              )
                            }
                            className="p-1 rounded-full hover:bg-red-100 dark:hover:bg-red-800 transition-all"
                          >
                            <Trash className="h-4 w-4 text-gray-400 dark:text-gray-500 hover:text-red-600 dark:hover:text-red-400" />
                          </button>
                        </SidebarMenuItem>
                      ))}
                  </SidebarMenu>
                </CollapsibleContent>
              </Collapsible>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
      </ScrollArea>

      {/* Sidebar Footer */}
      <SidebarFooter className="border-t">
        <SidebarMenu>
          {/* Settings */}
          <Dialog>
            <SidebarMenuItem className="mb-2">
              {/* <SidebarMenuButton asChild> */}
              <DialogTrigger>
                <p className={`flex items-center gap-2 cursor-pointer`}>
                  <Settings className="h-4 w-4" />
                  <span>Settings</span>
                </p>
              </DialogTrigger>
              {/* </SidebarMenuButton> */}
            </SidebarMenuItem>
            <DialogContent className="max-w-lg mx-auto h-[90vh]">
              <SettingsPage />
            </DialogContent>
          </Dialog>

          {/* Theme Toggle */}
          <SidebarMenuItem className="flex items-center justify-between">
            <ThemeModeToggle type="switch" />

            <div className="flex items-center gap-2">
              <a
                href="https://x.com/orbitcryptoai"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitterX className="h-4" />
              </a>
              <a
                href="https://discord.com/invite/E8zfpJH4HY"
                target="_blank"
                rel="noreferrer"
              >
                <BsDiscord className="h-4" />
              </a>
            </div>
          </SidebarMenuItem>

          {/* Docs */}
          {/* <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <p
                className={`flex items-center gap-2 cursor-pointer`}
                onClick={() =>
                  window.open(
                    "https://sphereone.notion.site/Surface-Area-d4883498ae5f41909e7fae69795645ad",
                    "_blank"
                  )
                }
              >
                <FileText className="h-4 w-4" />
                <span>Docs</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem> */}
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
}
