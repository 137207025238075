export const Onramp = ({ data }: { data: { order_link: string } }) => {
  const orderLink = data.order_link;

  return (
    <iframe
      src={`${orderLink}&themeName=dark&containerColor=1f2937&primaryColor=1d4fd8&secondaryColor=4b5563&cardColor=4b5563&primaryTextColor=fafafaff&secondaryTextColor=f9fafb&borderRadius=0.5&wgBorderRadius=0.5`}
      title="Onramper Widget"
      className="mt-4 h-[600px] w-full sm:w-96 border border-gray-300 rounded-lg"
      allow="accelerometer; autoplay; camera; gyroscope; payment"
    />
  );
};
