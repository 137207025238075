import { WalletsProvider } from "./use-wallets";
import { useMemo } from "react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider as SolanaWalletAdapterProvider,
} from "@solana/wallet-adapter-react";
import { TipLinkWalletAdapter } from "@tiplink/wallet-adapter";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { SolanaWalletProvider } from "./providers/solana-provider";
import { EvmWalletProvider } from "./providers/evm-provider";
import { SuiWalletProvider } from "./providers/sui-provider";
import { CosmosWalletProvider } from "./providers/cosmos-provider";
import { BitcoinWalletProvider } from "./providers/bitcoin-provider";

import { getFullnodeUrl } from "@mysten/sui/client";
import {
  createNetworkConfig,
  SuiClientProvider,
  WalletProvider,
} from "@mysten/dapp-kit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { darkTheme } from "../../components/themed/sui-themed-wallet-variables";
import "@mysten/dapp-kit/dist/index.css";
import { AptosWalletProvider } from "./providers/aptos-provider";

const queryClient = new QueryClient();

// @ts-ignore
const { networkConfig } = createNetworkConfig({
  localnet: { url: getFullnodeUrl("localnet") },
  mainnet: { url: getFullnodeUrl("mainnet") },
});

export function WalletContextProviders(props: { children: any }) {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = process.env.REACT_APP_SOLANA_RPC as string;

  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/anza-xyz/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      new PhantomWalletAdapter(),
      new TipLinkWalletAdapter({
        title: "Orbit",
        clientId: process.env.REACT_APP_TIPLINK_CLIENT_ID as string,
        theme: "dark",
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <SolanaWalletAdapterProvider wallets={wallets} autoConnect={true}>
        <QueryClientProvider client={queryClient}>
          <SuiClientProvider networks={networkConfig} defaultNetwork="mainnet">
            <WalletProvider theme={[{ variables: darkTheme }]}>
              <SolanaWalletProvider>
                <EvmWalletProvider>
                  <SuiWalletProvider>
                    <CosmosWalletProvider>
                      <AptosWalletProvider>
                        <BitcoinWalletProvider>
                          <WalletsProvider>{props.children}</WalletsProvider>
                        </BitcoinWalletProvider>
                      </AptosWalletProvider>
                    </CosmosWalletProvider>
                  </SuiWalletProvider>
                </EvmWalletProvider>
              </SolanaWalletProvider>
            </WalletProvider>
          </SuiClientProvider>
        </QueryClientProvider>
      </SolanaWalletAdapterProvider>
    </ConnectionProvider>
  );
}
