import { createContext, useContext, useEffect, useState } from "react";
import {
  WalletProvider,
  WalletSignerContextProvider,
} from "./providers/wallet-provider";
import { AuthSig, useEvmWallets } from "./providers/evm-provider";
import { useSolanaWallets } from "./providers/solana-provider";
import { useSuiWallets } from "./providers/sui-provider";
import { useCosmosWallets } from "./providers/cosmos-provider";
import { useAptosWallets } from "./providers/aptos-provider";
import { Types } from "aptos";
import { useBitcoinWallets } from "./providers/bitcoin-provider";

export type AssembledTransaction =
  | EvmTransactionData
  | SolanaTransactionData
  | SuiTransactionData
  | CosmosTransactionData
  | AptosTransactionData
  | BitcoinTransactionData;

export enum WalletType {
  EVM = "EVM",
  // AGENT = "AGENT",
  SOLANA = "SOLANA",
  SUI = "SUI",
  COSMOS = "COSMOS",
  APTOS = "APTOS",
  BITCOIN = "BITCOIN",
}

export const getWalletTypeFromTransaction = (tx: AssembledTransaction) => {
  if (typeof tx === "string") tx = JSON.parse(tx);
  if ("walletType" in tx && tx["walletType"] === "COSMOS") {
    return WalletType.COSMOS;
  } else if (
    ("version" in tx &&
      "gasData" in tx &&
      "inputs" in tx &&
      "commands" in tx) ||
    ("sender" in tx &&
      (tx as SuiTransactionData)["sender"] &&
      isSuiAddress((tx as SuiTransactionData)["sender"]))
  ) {
    return WalletType.SUI;
  } else if (
    "serializedTransaction" in tx ||
    "serializedTransactionMessage" in tx
  ) {
    return WalletType.SOLANA;
  } else if ("type" in tx && tx["type"] === "entry_function_payload") {
    return WalletType.APTOS;
  } else if ("toBitcoinAddress" in tx && "amount" in tx) {
    return WalletType.BITCOIN;
  } else {
    return WalletType.EVM;
  }
};

// Function to check if the address is a Sui wallet address
const isSuiAddress = (address: string | null): boolean => {
  if (!address) return false;
  // Example check: Sui addresses are typically 64 characters long and hexadecimal
  const suiAddressPattern = /^0x[a-fA-F0-9]{64}$/;
  return suiAddressPattern.test(address);
};

export interface CosmosTransactionData {
  amount: string;
  chainId: string;
  data: string;
  from: string;
  gasLimit: string;
  gasPrice: string;
  maxFeePerGas: string;
  routeType: string;
  rpc: string;
  tokenDenom: string;
  value: string;
  walletType: string;
}

export interface BitcoinTransactionData {
  toBitcoinAddress: string;
  amount: string;
  memo?: string;
}

export interface SolanaTransactionData {
  serializedTransaction?: string;
  serializedTransactionMessage?: string;
}

export interface EvmTransactionData {
  from: string;
  chainId: number;
  to: string;
  value: string;
  data: string;
  nonce: number;
  gasPrice?: string;
  gas?: string;
}

export interface SuiTransactionData {
  version: number;
  sender: string | null;
  expiration: any | null;
  gasData: {
    budget: string;
    price: string | null;
    owner: string | null;
    payment: string | null;
  };
  inputs: Array<{
    Pure?: { bytes: string };
    UnresolvedObject?: { objectId: string };
  }>;
  commands: Array<{
    SplitCoins?: {
      coin: { GasCoin: boolean };
      amounts: Array<{ Input: number }>;
    };
    MoveCall?: {
      package: string;
      module: string;
      function: string;
      typeArguments: string[];
      arguments: Array<{
        Input?: number;
        Result?: number;
        NestedResult?: [number, number];
      }>;
    };
    MergeCoins?: {
      destination: { GasCoin: boolean };
      sources: Array<{ NestedResult: [number, number] }>;
    };
    TransferObjects?: {
      objects: Array<{ NestedResult: [number, number] }>;
      address: { Input: number };
    };
  }>;
}

export type AptosTransactionData = Types.TransactionPayload;

interface WalletsContextProvider {
  loading: {
    [walletType in WalletType]: boolean;
  };
  signTransactions: (
    transactions: AssembledTransaction[],
    walletType?: WalletType
  ) => Promise<{ hashes: string[]; error: string | null }>;
  signMessage: (
    walletType: WalletType,
    message: string
  ) => Promise<void | string | Uint8Array>;
  signTypedMessage: (
    walletType: WalletType,
    message: string
  ) => Promise<string | Uint8Array>;
  addresses: { [walletType in WalletType]: string | null };
  connect: (walletType: WalletType, provider: WalletProvider) => Promise<void>;
  disconnect: (walletType: WalletType) => Promise<void>;
  providers: { [walletType in WalletType]: WalletProvider | null };
  agentsPreference: boolean;
  authSig: AuthSig | null;
  coinbaseMpc?: { address: string; type: string };
  setAgentsPreference: (value: boolean) => void;
}

const WalletsContext = createContext<WalletsContextProvider | undefined>(
  undefined
);

export const useWallets = () => {
  const context = useContext(WalletsContext);
  if (!context) {
    throw new Error("useWallets must be used within a WalletsContext");
  }
  return context;
};

export const WalletsProvider = ({ children }: { children: any }) => {
  const [loading, setLoading] = useState<{
    [walletType in WalletType]: boolean;
  }>({
    [WalletType.EVM]: false,
    // [WalletType.AGENT]: false,
    [WalletType.SOLANA]: false,
    [WalletType.SUI]: false,
    [WalletType.COSMOS]: false,
    [WalletType.APTOS]: false,
    [WalletType.BITCOIN]: false,
  });
  const [providers, setProviders] = useState<{
    [walletType in WalletType]: WalletProvider | null;
  }>({
    [WalletType.EVM]: null,
    // [WalletType.AGENT]: null,
    [WalletType.SOLANA]: null,
    [WalletType.SUI]: null,
    [WalletType.COSMOS]: null,
    [WalletType.APTOS]: null,
    [WalletType.BITCOIN]: null,
  });

  // providers
  const evm = useEvmWallets();
  const solana = useSolanaWallets();
  const sui = useSuiWallets();
  const cosmos = useCosmosWallets();
  const aptos = useAptosWallets();
  const bitcoin = useBitcoinWallets();

  useEffect(() => {
    setProviders({
      ...providers,
      [WalletType.SOLANA]: solana.provider as WalletProvider,
    });
  }, [solana.provider]);

  async function connect(walletType: WalletType, provider: WalletProvider) {
    setProviders({
      ...providers,
      [walletType]: provider,
    });
    setLoading({
      ...loading,
      [walletType]: true,
    });

    const providerContext = _getProvider(provider);
    if (!providerContext) {
      throw new Error("Provider not found");
    }

    try {
      await providerContext.connect(provider);
    } catch (e) {
      console.error("error", e);
    } finally {
      setLoading({
        ...loading,
        [walletType]: false,
      });
    }
  }

  function disconnect(walletType: WalletType) {
    if (walletType === WalletType.SOLANA) {
      return solana.disconnect();
    }

    if (!providers[walletType]) {
      throw new Error("Provider not found");
    }

    const providerContext = _getProvider(
      providers[walletType] as WalletProvider
    );
    if (!providerContext) {
      throw new Error("Provider not found");
    }

    setProviders({
      ...providers,
      [walletType]: null,
    });

    return providerContext.disconnect();
  }

  // sign transactions
  async function signTransactions(
    transactions: AssembledTransaction[],
    walletType?: WalletType
  ): Promise<{ hashes: string[]; error: string | null }> {
    if (transactions.length === 0) {
      return { hashes: [], error: null };
    }
    const hashes: string[] = [];

    if (!walletType) {
      walletType = getWalletTypeFromTransaction(transactions[0]);
    }

    try {
      if (!providers[walletType]) throw new Error("Provider not found");

      const p = _getProvider(providers[walletType] as WalletProvider);
      if (!p) throw new Error("Provider not found");

      for (let i = 0; i < transactions.length; i++) {
        const isLastTransaction = i === transactions.length - 1;
        const hash = await p.signAndSendTransaction(
          transactions[i],
          isLastTransaction
        );
        hashes.push(hash);
      }

      return { hashes, error: null };
    } catch (e: any) {
      console.error("e", e);
      const error =
        e?.error?.data?.message ?? e.message ?? "Error signing transactions";

      return { hashes, error: error };
    }
  }

  async function signMessage(
    walletType: WalletType,
    message: string
  ): Promise<void | string | Uint8Array> {
    if (!providers[walletType]) throw new Error("Provider not found");
    const p = _getProvider(providers[walletType] as WalletProvider);
    if (!p) throw new Error("Provider not found");
    return p.signMessage(message);
  }

  async function signTypedMessage(
    walletType: WalletType,
    message: string
  ): Promise<string | Uint8Array> {
    if (!providers[walletType]) throw new Error("Provider not found");
    const p = _getProvider(providers[walletType] as WalletProvider);
    if (!p) throw new Error("Provider not found");
    return p.signTypedMessage!(message);
  }

  // ====================================================
  // private functions ==================================
  // ====================================================

  function _getProvider(provider: WalletProvider) {
    const providers: {
      [provider in WalletProvider]: WalletSignerContextProvider;
    } = {
      [WalletProvider.EVM]: evm,
      // [WalletProvider.AGENT]: evm,
      [WalletProvider.PHANTOM]: solana,
      [WalletProvider.TIPLINK]: solana,
      [WalletProvider.SUI]: sui,
      [WalletProvider.COSMOS]: cosmos,
      [WalletProvider.APTOS]: aptos,
      [WalletProvider.BITCOIN]: bitcoin,
    };

    return providers[provider];
  }

  return (
    <WalletsContext.Provider
      value={{
        authSig: evm.authSig,
        loading,
        connect,
        disconnect,
        providers,
        addresses: {
          [WalletType.EVM]: evm.address ?? null,
          // [WalletType.AGENT]: evm.agent_address ?? null,
          [WalletType.SOLANA]: solana.address ?? null,
          [WalletType.SUI]: sui.address ?? null,
          [WalletType.COSMOS]: cosmos.address ?? null,
          [WalletType.APTOS]: aptos.address ?? null,
          [WalletType.BITCOIN]: bitcoin.address ?? null,
        },
        signTransactions,
        signMessage,
        signTypedMessage,
        agentsPreference: evm.agentsPreference,
        setAgentsPreference: evm.setAgentsPreference,
        coinbaseMpc: evm.wallets.filter((w) => w.type === "mpc").pop(), // should only have 1 or none in the list
      }}
    >
      {children}
    </WalletsContext.Provider>
  );
};
