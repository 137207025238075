import { useState, useEffect } from "react";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Switch } from "../components/ui/switch";
import { Slider } from "../components/ui/slider";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";

import {
  AllowancePreference,
  usePreferences,
  GasPreference,
  defaultPreferences,
} from "../hooks/use-preferences";
import { useToast } from "../hooks/use-toast";

export default function SettingsPage() {
  const { preferences, updatePreferences } = usePreferences();
  const { toast } = useToast();

  const [localPreferences, setLocalPreferences] = useState(preferences);
  const [customGasPrice, setCustomGasPrice] = useState(
    preferences?.gas.value || "21000"
  );

  useEffect(() => {
    setLocalPreferences(preferences);
  }, [preferences]);

  const handleSavePreferences = () => {
    updatePreferences(localPreferences);
    toast({
      description: "Your preferences have been saved",
    });
  };

  const handleResetToDefault = () => {
    updatePreferences(defaultPreferences);
    toast({
      description: "Your preferences have been reset to default",
    });
  };

  return (
    <div className="flex flex-col text-foreground overflow-hidden">
      <div className="mb-14 overflow-y-auto">
        <h1 className="text-3xl font-bold mb-6">Settings</h1>
        <div className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Trading Preferences</CardTitle>
              <CardDescription>
                Configure your trading parameters
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="space-y-2">
                <Label htmlFor="slippage">Slippage Tolerance (%)</Label>
                <Select
                  value={localPreferences.slippage.toString()}
                  onValueChange={(value) =>
                    setLocalPreferences((prev) => ({
                      ...prev,
                      slippage: Number(value),
                    }))
                  }
                >
                  <SelectTrigger id="slippage">
                    <SelectValue placeholder="Select slippage" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="0.1">0.1%</SelectItem>
                    <SelectItem value="0.5">0.5%</SelectItem>
                    <SelectItem value="1">1%</SelectItem>
                    <SelectItem value="2">2%</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label>Allowance</Label>
                <RadioGroup
                  defaultValue={localPreferences.allowance}
                  onValueChange={(value: AllowancePreference) =>
                    setLocalPreferences((prev) => ({
                      ...prev,
                      allowance: value,
                    }))
                  }
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value={AllowancePreference.EXACT}
                      id={AllowancePreference.EXACT}
                    />
                    <Label htmlFor={AllowancePreference.EXACT}>Exact</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value={AllowancePreference.UNLIMITED}
                      id={AllowancePreference.UNLIMITED}
                    />
                    <Label htmlFor={AllowancePreference.UNLIMITED}>
                      Unlimited
                    </Label>
                  </div>
                </RadioGroup>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Gas Settings</CardTitle>
              <CardDescription>
                Manage your transaction gas preferences
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="gas-price">Gas Price Strategy</Label>
                <Select
                  value={localPreferences.gas.type}
                  onValueChange={(value) => {
                    setLocalPreferences((prev) => ({
                      ...prev,
                      gas: {
                        type: value as GasPreference,
                        value:
                          value === GasPreference.CUSTOM
                            ? customGasPrice.toString()
                            : null,
                      },
                    }));
                  }}
                >
                  <SelectTrigger id="gas-price">
                    <SelectValue placeholder="Select gas price strategy" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="slow">Slow (Cheapest)</SelectItem>
                    <SelectItem value="medium">Medium</SelectItem>
                    <SelectItem value="fast">Fast</SelectItem>
                    <SelectItem value="custom">Custom</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              {localPreferences.gas.type === GasPreference.CUSTOM && (
                <div className="space-y-2">
                  <Label htmlFor="custom-gas">Custom Gas Price (Gwei)</Label>
                  <Input
                    id="custom-gas"
                    type="number"
                    placeholder="Enter custom gas price"
                    value={customGasPrice}
                    onChange={(e) => {
                      const newCustomGas = e.target.value;
                      setCustomGasPrice(newCustomGas);
                      setLocalPreferences((prev) => ({
                        ...prev,
                        gas: {
                          type: GasPreference.CUSTOM,
                          value: newCustomGas,
                        },
                      }));
                    }}
                  />
                </div>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Automation Settings</CardTitle>
              <CardDescription>
                Configure your automated trading preferences
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              {/* <div className="flex items-center justify-between">
                <Label htmlFor="auto-rebalance">Auto-rebalance Portfolio</Label>
                <Switch
                  id="auto-rebalance"
                  checked={localPreferences.autoRebalance}
                  onCheckedChange={(value: any) =>
                    setLocalPreferences((prev) => ({
                      ...prev,
                      autoRebalance: value,
                    }))
                  }
                />
              </div> */}
              <div className="space-y-2">
                <Label>Risk Tolerance</Label>
                <Slider
                  value={[localPreferences.riskTolerance]}
                  onValueChange={(value: any) =>
                    setLocalPreferences((prev) => ({
                      ...prev,
                      riskTolerance: value[0],
                    }))
                  }
                  max={100}
                  step={1}
                />
                <div className="text-sm text-muted-foreground">
                  Current: {localPreferences.riskTolerance}% (Higher value
                  indicates higher risk tolerance)
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 h-[50px] border-t flex items-center justify-end p-4 py-8 z-50">
        <Button onClick={handleSavePreferences} variant={"outline"}>
          Save Settings
        </Button>
        <Button
          onClick={handleResetToDefault}
          variant={"outline"}
          className="ml-2"
        >
          Use Defaults
        </Button>
      </div>
    </div>
  );
}
