import { createContext, useContext, useEffect } from "react";
import { WalletProvider, WalletSignerContextProvider } from "./wallet-provider";
import { VersionedMessage, VersionedTransaction } from "@solana/web3.js";
import { AssembledTransaction, SolanaTransactionData } from "../use-wallets";
import { Buffer } from "buffer";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PhantomWalletName } from "@solana/wallet-adapter-wallets";
import { GoogleViaTipLinkWalletName } from "@tiplink/wallet-adapter";
import { useAppDispatch } from "../../../redux/hooks";
import {
  addConnectedWallet,
  getBalances,
  removeChatHistory,
  selectedWalletAddress,
  setChatHistory,
  updateBalancesAfterTransaction,
} from "../../../redux/slices/user";
import { serverApi } from "../../../services/server";

const SolanaWalletContext = createContext<
  WalletSignerContextProvider | undefined
>(undefined);

export const useSolanaWallets = () => {
  const context = useContext(SolanaWalletContext);
  if (!context) {
    throw new Error(
      "useSolanaWallets must be used within a SolanaWalletContext"
    );
  }
  return context;
};

export const SolanaWalletProvider = ({ children }: { children: any }) => {
  const { connection } = useConnection();
  const solWallet = useWallet();
  const dispatch = useAppDispatch();

  const fetchBalances = async (update: boolean = false) => {
    if (solWallet.publicKey?.toBase58()) {
      const response = await serverApi.getBalances(
        null,
        solWallet.publicKey?.toBase58() ?? null,
        null,
        null,
        null
      );
      if (update) return dispatch(updateBalancesAfterTransaction(response));
      dispatch(selectedWalletAddress(solWallet.publicKey?.toBase58()));
      dispatch(addConnectedWallet(solWallet.publicKey?.toBase58()));
      return dispatch(getBalances(response));
    }
  };

  const getChatHistory = async (wallet_address: string | null) => {
    if (!wallet_address) {
      const emptyChatHistory: {
        [chatId: string]: {
          messages: {
            message: string;
            agent: string;
            data?: any;
            component?: string | null | undefined;
          }[];
          lastUpdatedAt?: string;
          associated_wallet: string;
        };
      } = {};
      dispatch(setChatHistory(emptyChatHistory));
      return;
    }
    const response = await serverApi.getChatsHistory(wallet_address);
    const chats = response.data.chats_history;
    dispatch(setChatHistory(chats));
  };

  useEffect(() => {
    fetchBalances();
    getChatHistory(solWallet.publicKey?.toBase58() ?? null);
  }, [solWallet.publicKey?.toBase58()]);

  async function connect(provider: WalletProvider): Promise<void> {
    if (provider === WalletProvider.PHANTOM) {
      solWallet.select(PhantomWalletName);
    } else if (provider === WalletProvider.TIPLINK) {
      solWallet.select(GoogleViaTipLinkWalletName);
    } else {
      throw new Error("Unsupported provider");
    }
  }

  function disconnect() {
    if (solWallet.publicKey?.toBase58()) {
      dispatch(removeChatHistory(solWallet.publicKey?.toBase58()));
    }
    return solWallet.disconnect();
  }

  async function signAndSendTransaction(
    tx: AssembledTransaction,
    isLastTransaction: boolean
  ): Promise<string> {
    tx = tx as SolanaTransactionData;
    if (tx.serializedTransaction) {
      const bufferized = Buffer.from(tx.serializedTransaction, "base64");
      const versionedTransaction = VersionedTransaction.deserialize(bufferized);

      const signature = await solWallet.sendTransaction(
        versionedTransaction,
        connection
      );
      return signature;
    } else if (tx.serializedTransactionMessage) {
      const bufferized = Buffer.from(tx.serializedTransactionMessage, "base64");
      const versionedMessage = VersionedMessage.deserialize(bufferized);
      const versionedTransaction = new VersionedTransaction(versionedMessage);

      const signature = await solWallet.sendTransaction(
        versionedTransaction,
        connection
      );
      if (isLastTransaction) {
        fetchBalances(true);
      }
      return signature;
    } else {
      throw new Error("Serialized transaction is required");
    }
  }

  async function signMessage(message: string): Promise<Uint8Array> {
    if (!solWallet.signMessage) {
      throw new Error("signMessage is not supported");
    }
    const encodedMessage = new TextEncoder().encode(message);
    const signature = await solWallet.signMessage(encodedMessage);
    return signature;
  }

  return (
    <SolanaWalletContext.Provider
      value={{
        authSig: null,
        agentsPreference: false,
        setAgentsPreference: () => {},
        address: solWallet.publicKey?.toBase58() ?? null,
        provider: solWallet.wallet?.adapter.name.toLowerCase(),
        connect,
        disconnect,
        signAndSendTransaction,
        signMessage,
      }}
    >
      {children}
    </SolanaWalletContext.Provider>
  );
};
