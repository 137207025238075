"use client";

import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { Input } from "../components/ui/input";
import { Card, CardContent, CardTitle } from "../components/ui/card";
import { ArrowRight } from "lucide-react";

import { images } from "../assets/images/index";
import { useTheme } from "../components/theme-provider";
import ConnectWalletButton from "../components/connect-wallet-button";
import { useWallets } from "../hooks/wallets/use-wallets";
import { ThemedButton } from "../components/themed/ThemedButton";
import QuickActionCards from "../components/chat/message-actions/QuickActionCards";
import { useChat } from "../hooks/use-chat";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ErrorAlert } from "./executor-gpt";
import { selectPreferredAgentThunk } from "../redux/slices/gptThunk";
import { setCurrentChatId } from "../redux/slices/user";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const { theme } = useTheme();

  const { addresses, providers } = useWallets();
  const {
    chatId,
    appendMessage,
    superAgent,
    error,
    setError,
    restoreOrSetNewChat,
    setMessages,
  } = useChat();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleNextStep = (step: number) => {
    setStep(step);
  };

  useEffect(() => {
    dispatch(setCurrentChatId(null));
  }, []);

  useEffect(() => {
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
      handleNextStep(2);
    }

    if (Object.values(addresses).some((address) => address !== null)) {
      handleNextStep(3);
    }
  }, [providers, addresses]);

  const handleNameSubmit = () => {
    if (name.trim()) {
      localStorage.setItem("userName", name.trim());
      handleNextStep(2);
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyPress = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === "Enter" && step === 3 && inputRef.current) {
        const message = inputRef.current.value.trim();
        if (message) {
          setMessages([]);
          dispatch(setCurrentChatId(null));
          restoreOrSetNewChat(null, null); // It's a new chat if chatting form Home Page
          await dispatch(selectPreferredAgentThunk(superAgent)).unwrap();
          await appendMessage(message);
          navigate("/chat");
        }
      }
    },
    [step, addresses, chatId]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Card className="w-full max-w-md mx-auto">
            <CardContent className="flex flex-col items-center justify-center space-y-6 p-6">
              <img
                src={images.OrbitStanding}
                width={100}
                height={100}
                alt="Orbit Logo"
                className="rounded-full"
              />
              <CardTitle className="text-2xl font-bold text-center">
                {"Hi! I'm Orbit, your DeFi Companion."}
              </CardTitle>
              <ThemedButton
                onClick={() => handleNextStep(1)}
                className="w-full"
              >
                Get started <ArrowRight className="ml-2 h-4 w-4" />
              </ThemedButton>
            </CardContent>
          </Card>
        );
      case 1:
        return (
          <Card className="w-full max-w-md mx-auto">
            <CardContent className="flex flex-col items-center justify-center space-y-6 p-6">
              <CardTitle className="text-2xl font-bold">
                First, what can I call you?
              </CardTitle>
              <Input
                placeholder="Your first name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNameSubmit();
                  }
                }}
              />
              <ThemedButton
                onClick={handleNameSubmit}
                disabled={!name.trim()}
                className="w-full"
              >
                Next <ArrowRight className="ml-2 h-4 w-4" />
              </ThemedButton>
            </CardContent>
          </Card>
        );
      case 2:
        return (
          <Card className="w-full max-w-md mx-auto">
            <CardContent className="flex flex-col items-center justify-center space-y-6 p-6">
              <CardTitle className="text-2xl font-bold">
                Connect your wallet
              </CardTitle>
              <p className="text-center text-muted-foreground">
                Connect your crypto wallet to get personalized money-making
                suggestions.
              </p>
              <ConnectWalletButton chatStarted={false} />
            </CardContent>
          </Card>
        );
      case 3:
        return (
          <div className="w-full mx-auto flex flex-col items-center justify-center p-4">
            <h1 className="text-3xl font-bold mb-4 sm:mb-8">
              What can I help with, {name}?
            </h1>
            <div className="flex flex-col items-center justify-center w-full relative mb-2 sm:mb-4 max-w-2xl px-6 sm:px-2">
              <Input
                ref={inputRef}
                placeholder="What do you want to do?"
                className="md:w-full sm:w-2/3 w-full sm:px-4 py-2 rounded-full border border-gray-600"
              />
            </div>
            <div className="flex flex-wrap justify-center max-w-7xl">
              <QuickActionCards />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-full bg-gradient-to-b from-primary/20 to-background items-center justify-center p-4">
      <ErrorAlert error={error} setError={setError} />
      {renderStep()}
    </div>
  );
}
