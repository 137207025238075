interface TokenCardProps {
  type: string;
  token: {
    logoURI: string;
    symbol: string;
  };
  chain: string;
  amount: string;
  usdValue: string | number;
  chainImage?: string;
  defaultImage?: string;
}

const TokenCard = ({
  type,
  token,
  chain,
  amount,
  usdValue,
  chainImage,
  defaultImage,
}: TokenCardProps) => {
  return (
    <div className="flex flex-row bg-background border-gray-500/20 shadow-md border items-start w-3/4 sm:w-4/5 p-3 rounded-lg">
      <div className="flex flex-col w-full justify-center gap-4">
        {/* Title */}
        <p className="text-sm sm:text-lg text-left font-bold">{type}</p>

        {/* Image, Symbol and Chain */}
        <div className="flex w-full justify-start">
          {/* Token and Chain Images Container */}
          <div className="flex w-1/2 items-center relative">
            {/* Main Token Image */}
            <div className="w-12 h-12 relative">
              <img
                src={token.logoURI}
                alt={token.symbol}
                className="w-full h-full rounded-full border border-gray-500/20 object-cover"
              />
              {/* Chain Image Overlay */}
              <img
                src={chainImage || defaultImage}
                alt={`${chain} Chain`}
                className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full object-cover border border-background"
              />
            </div>
          </div>

          {/* Symbol and Chain Text */}
          <div className="flex h-12 flex-col justify-between items-start text-left">
            <span className="font-semibold text-xl tracking-tight">
              {token.symbol}
            </span>
            <span className="text-xs text-right font-normal text-muted-foreground">
              {chain.toUpperCase()}
            </span>
          </div>
        </div>

        {/* Amount and USD Value */}
        <div className="flex w-full flex-row items-baseline justify-between flex-wrap">
          <span className="font-medium text-base min-w-16">{amount}</span>
          <span className="text-base text-left min-w-16">
            ${usdValue}
          </span>
        </div>
      </div>
    </div>
  );
};

export { TokenCard };
export type { TokenCardProps };
