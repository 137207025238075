import ConnectWalletButton, {
  walletTypeToProviders,
} from "./connect-wallet-button";

import { PanelLeftOpen, PanelRightOpen, Pencil, Wallet } from "lucide-react";
import WalletPopUp from "./balance-popup";
import { useWallets } from "../hooks/wallets/use-wallets";
import { useChat } from "../hooks/use-chat";
import { Button } from "./ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useNavigate } from "react-router-dom";
import { ThemedButton } from "./themed/ThemedButton";
import { useSidebar } from "./ui/sidebar";
import { useAppDispatch } from "../redux/hooks";
import { setCurrentChatId } from "../redux/slices/user";

export default function Header({
  chatStarted,
  showBalances,
  setShowBalances,
}: {
  chatStarted: boolean;
  showBalances: boolean;
  setShowBalances: (show: boolean) => void;
}) {
  const { messages, loading, restoreOrSetNewChat, setMessages } = useChat();
  const { toggleSidebar, state, isMobile } = useSidebar();
  const { addresses } = useWallets();
  const dispatch = useAppDispatch();
  const connectedWallets = walletTypeToProviders.filter(
    ({ name }) => addresses[name] !== null
  );

  return (
    <div className="px-4 py-1 border-b border-b-gray-200 dark:border-b-gray-700 flex justify-between gap-2">
      <div className="flex w-full justify-start items-center">
        <Button
          variant="outline"
          className={`self-end mt-auto flex mr-2 ${
            state === "expanded" ? "hidden" : "block"
          } ${isMobile ? "block" : ""}`}
          onClick={toggleSidebar}
        >
          {state === "expanded" ? (
            <PanelRightOpen className="h-4 w-4" />
          ) : (
            <PanelLeftOpen className="h-4 w-4" />
          )}
        </Button>
        {Object.values(addresses).filter((w) => w !== null).length > 0 && (
          <div className="flex w-full justify-start items-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="outline"
                    disabled={messages.length === 0 || loading}
                    className="self-end mt-auto flex sm:w-fit w-full"
                    onClick={() => {
                      dispatch(setCurrentChatId(null));
                      restoreOrSetNewChat(null, null);
                      setMessages([]);
                    }}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>New Chat</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>

      <div
        className={`${Object.values(addresses).filter((w) => w !== null).length > 0 ? "justify-center" : "justify-start"} flex w-full items-center`}
      >
        <h1 className="text-lg w-full justify-center text-center font-semibold">
          Orbit
        </h1>
      </div>

      <div className="flex w-full justify-end items-center space-x-1">
        {Object.values(addresses).filter((w) => w !== null).length === 0 && (
          <div className="flex items-center">
            <ConnectWalletButton chatStarted={chatStarted} />
          </div>
        )}

        {connectedWallets.length > 0 && (
          <Button
            variant="outline"
            onClick={() => setShowBalances(!showBalances)}
            className="flex items-center justify-center text-center"
          >
            <div className="flex sm:hidden">{connectedWallets.length}</div>
            <Wallet className="sm:h-8 sm:w-8 h-6 w-6 self-center" />
            <div className="hidden sm:flex flex-wrap gap-px md:gap-1 lg:gap-2 ">
              {connectedWallets.map(({ name, iconSrc }) => (
                <img
                  key={name}
                  src={require(`../assets/images/${iconSrc}.png`)}
                  alt={`${name} Icon`}
                  className="sm:w-5 sm:h-5 w-4 h-4 rounded-full border border-gray-300 dark:bg-gray-200"
                />
              ))}
            </div>
          </Button>
        )}
        {showBalances && (
          <div className="fixed top-10 right-4 z-30 transition-all ease-in-out duration-300">
            <WalletPopUp
              setShowBalances={setShowBalances}
              chatStarted={chatStarted}
            />
          </div>
        )}
      </div>
    </div>
  );
}
