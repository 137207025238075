import TransactionHash from "./transaction-hash";
import { Submit } from "../../../hooks/use-chat";
import { BridgeQuote } from "./bridge-quote";
import LockPeriod from "../lock-period";
import { TxStatus } from "./tx-status";
import { Message } from "../../../hooks/use-chat";
import { FundTipLink } from "./fund-tiplink";
import { SwapQuote } from "./swap-quote";
import { Onramp } from "./onramp";
import { TokenChart } from "./token-chart";
import { TransferQuote } from "./transfer-quote";
import { LiquidityPerpsSignTransactionView } from "./perps-sign-transaction";
import { PerpsOrdersSelectOptionView } from "./kinetix/limit-order-select-option";
import { SignMessageView } from "./kinetix/sign-message";
import { PerpsSignTransactionView } from "./kinetix/perps-sign-transaction";
import { DoubleSideLiquidityQuote } from "./double-side-liquidity";
import { YieldActivePositions } from "./automated_yield/yield_active_positions";
import { AaveV3PoolsOptions } from "./automated_yield/yield_available_pools";
import NFTGridView from "./nfts";
import { MemeTradeOptions } from "./meme-trade-options";

export interface RenderComponentProps {
  component: string;
  data: any;
  chatId: string;
  enabled?: boolean;
  submit: (data: Submit) => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  sendMessageHidden: (message: string) => Promise<void>;
  accessToken: string;
  onDone: () => void;
  active: boolean;
}

export enum RenderComponents {
  BRIDGE = "bridge",
  TRANSFER = "transfer",
  HASH = "hash",
  TX_STATUS = "tx_status",
  SWAP = "swap",
  ONRAMP = "onramp",
  LOADING = "loading",
  FUND_TIPLINK = "fund_tiplink",
  NFT = "nft",
  CURRENCY_CHART = "currency_chart",
  LIQUIDITY_PERPS_SIGN_TRANSACTION = "liquidity_perps_sign_transaction",
  PERPS_ORDER_SELECT_OPTION = "perps_orders_select_option",
  SIGN_MESSAGE = "sign_message",
  PERPS_SIGN_TRANSACTION = "perp_sign_transaction",
  QUICKSWAP_PERPS = "quickswap_perp_transaction",
  STAKE = "stake",
  DOUBLE_SIDE_LIQUIDITY = "double_side_liquidity",
  YIELD_ACTIVE_POSITIONS = "yield_active_positions",
  AAVE_V3_POOLS_OPTIONS = "aave_v3_pools_options",
  MEME_TRADE_OPTIONS = "meme_trade_options",
}

export const RenderComponent = ({
  component,
  data,
  submit,
  chatId,
  setMessages,
  sendMessageHidden,
  accessToken,
  onDone,
  active,
}: RenderComponentProps) => {
  function renderComponent() {
    switch (component) {
      case RenderComponents.BRIDGE:
        return (
          <BridgeQuote
            data={data}
            submit={submit}
            active={active}
            onDone={onDone}
            setMessages={setMessages}
            sendMessageHidden={sendMessageHidden}
          />
        );
      case RenderComponents.TX_STATUS:
        return <TxStatus data={data} />;
      case RenderComponents.HASH:
        return <TransactionHash data={data} />;
      case RenderComponents.SWAP:
        return (
          <SwapQuote
            data={data}
            chatId={chatId}
            setMessages={setMessages}
            accessToken={accessToken}
            active={active}
            onDone={onDone}
            submit={submit}
          />
        );
      case RenderComponents.TRANSFER:
        return (
          <TransferQuote
            data={data}
            submit={submit}
            active={active}
            onDone={onDone}
          />
        );
      case RenderComponents.ONRAMP:
        return <Onramp data={data} />;
      case RenderComponents.FUND_TIPLINK:
        return <FundTipLink data={data} active={active} onDone={onDone} />;
      case RenderComponents.NFT:
        return <NFTGridView data={data} />;
      case RenderComponents.CURRENCY_CHART:
        return <TokenChart data={data} />;
      case RenderComponents.LOADING:
        return (
          <div className="flex flex-row mb-6">
            <div className={`ml-3 max-w-screen-2xl`}>
              <div className="text-gray-300 mt-1 animate-pulse">Loading...</div>
            </div>
          </div>
        );
      case RenderComponents.PERPS_ORDER_SELECT_OPTION:
        return (
          <PerpsOrdersSelectOptionView
            data={data}
            sendMessageHidden={sendMessageHidden}
          />
        );
      case RenderComponents.SIGN_MESSAGE:
        return (
          <SignMessageView data={data} sendMessageHidden={sendMessageHidden} />
        );
      case RenderComponents.LIQUIDITY_PERPS_SIGN_TRANSACTION:
        return (
          <LiquidityPerpsSignTransactionView
            data={data}
            submit={submit}
            active={active}
            onDone={onDone}
            setMessages={setMessages}
          />
        );
      case RenderComponents.PERPS_SIGN_TRANSACTION:
        return (
          <PerpsSignTransactionView
            data={data}
            submit={submit}
            active={active}
            onDone={onDone}
            setMessages={setMessages}
          />
        );
      case RenderComponents.DOUBLE_SIDE_LIQUIDITY:
        return (
          <DoubleSideLiquidityQuote
            data={data}
            submit={submit}
            active={active}
            onDone={onDone}
          />
        );
      case RenderComponents.YIELD_ACTIVE_POSITIONS:
        return <YieldActivePositions data={data} onDone={onDone} />;
      case RenderComponents.AAVE_V3_POOLS_OPTIONS:
        return (
          <AaveV3PoolsOptions
            data={data}
            sendMessageHidden={sendMessageHidden}
          />
        );
      case RenderComponents.MEME_TRADE_OPTIONS:
        return (
          <MemeTradeOptions data={data} sendMessageHidden={sendMessageHidden} />
        );
      default:
        return (
          <pre className="break-words whitespace-pre-wrap">
            Error: No component called {component}
          </pre>
        );
    }
  }

  return (
    <div>
      <LockPeriod chains={[data.from_chain, data.to_chain]} />
      {renderComponent()}
    </div>
  );
};
