import React, { useEffect, useState } from "react";
import { Message, useChat } from "../../hooks/use-chat";
import { Info, Send } from "lucide-react";
import { Textarea } from "../ui/text-area";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { ScrollArea } from "../ui/scroll-area";

export const ORBIT_TOKEN_ADDRESS = "GRIFT";

export const normal_commands = [
  `Swap 0.01 ETH to USDC on Base chain`,
  `Bridge 0.01 ETH from Base chain to Arbitrum chain`,
  `Bridge 5 USDC from Linea to Base`,
];

export const orbit_commands = [
  `Orbit, blockchain wizard! Swap 0.1 Sol to ${ORBIT_TOKEN_ADDRESS} on Solana?`,
  // `Hey Orbit, can we swap 0.3 Sol to ${ORBIT_TOKEN_ADDRESS} on Solana chain?`,
];

interface ChatInputProps {
  loading: boolean;
  messages: Message[];
  input: string;
  setInput: (m: string) => any;
  onClick: () => any;
  appendMessage: (m?: string) => any;
}

export default function ChatInput({
  loading,
  input,
  setInput,
  onClick,
}: ChatInputProps) {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onClick();
    }
  };

  const { superAgent, appendMessage } = useChat();

  const [commands, setCommands] = useState({
    orbit_commands,
    normal_commands,
  });

  useEffect(() => {
    if (superAgent) {
      setCommands({
        orbit_commands,
        normal_commands: [...normal_commands, ...superAgent.examples],
      });
    }
  }, [superAgent]);

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;

      if (inputRef.current.scrollHeight > inputRef.current.offsetHeight) {
        inputRef.current.style.overflowY = "auto";
        inputRef.current.style.height = `${inputRef.current.offsetHeight}px`;
      } else {
        inputRef.current.style.overflowY = "hidden";
      }
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [input]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="w-full flex gap-2 flex-col sm:flex-row"
    >
      <div className="relative flex-1 flex items-center justify-center">
        <div className="flex flex-col w-full">
          <div className="relative">
            {/* <div className="flex overflow-x-auto mb-2">
              <div className="flex flex-row gap-2 pr-4">
                {[
                  ...commands.orbit_commands,
                  ...commands.normal_commands.slice(0, 5),
                ].map((command, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      !loading && appendMessage(command);
                    }}
                    className="flex-shrink-0 text-xs rounded-full p-2 border border-gray-300 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800"
                  >
                    {command}
                  </button>
                ))}
              </div>
            </div> */}
            <Textarea
              ref={inputRef}
              className="pr-12 resize-none overflow-hidden max-h-[70px] align-middle items-center"
              placeholder="Talk to Orbit..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={loading}
              rows={1}
            />
            {loading ? (
              <div className="absolute right-1 bottom-px h-8 w-8 items-center">
                {/* Loading spinner */}
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className="absolute right-2 bottom-1 flex gap-2">
                <Dialog>
                  <DialogTrigger>
                    <Button variant="ghost" size="icon" className="h-8 w-8">
                      <Info className="h-4 w-4" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle className="mb-2">
                        Useful Commands
                      </DialogTitle>
                    </DialogHeader>
                    <ScrollArea className="h-full max-h-[50vh] w-full pr-4">
                      <div className="flex flex-col h-full w-full space-y-2">
                        {[
                          ...commands.orbit_commands,
                          ...commands.normal_commands,
                        ].map((command) => (
                          <Button
                            key={command}
                            variant="outline"
                            className="flex-1 p-3 items-center justify-start w-full text-left rounded-lg
                            "
                            onClick={() => appendMessage(command)}
                          >
                            <div className="py-1 whitespace-pre-wrap break-words w-full">
                              {command}
                            </div>
                          </Button>
                        ))}
                      </div>
                    </ScrollArea>
                  </DialogContent>
                </Dialog>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  disabled={loading}
                  type="submit"
                >
                  <Send className="h-4 w-4" />
                  <span className="sr-only">Send message</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
