import SphereLogo from "./SphereIconLogoGreen.png";
import BuilderVector from "./builder-vector.png";
import DefaultCryptoLogo from "./DefaultCryptoLogo.png";
import AnotherSphereLogo from "./Sphere.svg";
import SphereLogoWhite from "./logo-white.svg";
import OrbitStanding from "./orbit-standing.png";

export const images = {
  AnotherSphereLogo: AnotherSphereLogo,
  SphereLogoWhite: SphereLogoWhite, // Light Mode
  SphereLogo: SphereLogo,
  BuilderSupport: BuilderVector,
  DefaultCryptoLogo: DefaultCryptoLogo,
  DefaultAgentLogo:
    "https://firebasestorage.googleapis.com/v0/b/sphereone-testing.appspot.com/o/Frame-9.png?alt=media&token=9c05b74d-9948-445f-936d-18f253b61b75",
  OrbitStanding: OrbitStanding,
  OrbitLogo:
    "https://firebasestorage.googleapis.com/v0/b/sphereone-testing.appspot.com/o/orbit%2F4.png?alt=media&token=5b33127a-8370-4e04-b6c1-07cdfaf288e3",
};
