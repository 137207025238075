import { WalletContextProviders } from "./hooks/wallets/context";
import { ChatProvider, useChat } from "./hooks/use-chat";
import { PreferencesProvider } from "./hooks/use-preferences";
import { Outlet } from "react-router-dom";
import SideBar from "./components/sidebar";
import Header from "./components/header";
import { useEffect, useState } from "react";
// import FCMHandler from "./utils/FCMHandler";
import { useAppDispatch } from "./redux/hooks";
import { loadSavedAgentsFromStorage } from "./redux/slices/user";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadSavedAgentsFromStorage());
  }, [dispatch]);

  const { messages } = useChat();
  const [showBalances, setShowBalances] = useState(false);
  return (
    <PreferencesProvider>
      <WalletContextProviders>
        <ChatProvider>
          <div className="flex h-screen w-screen overflow-hidden">
            {/* Sidebar on the left, taking up only required width */}
            {/* <FCMHandler /> */}
            <SideBar />

            {/* Main content area with flex layout */}
            <div className="flex flex-col flex-1 overflow-hidden">
              {/* Header at the top, fixed in place */}
              <div
                className="flex justify-center items-center bg-purple-400 text-sm text-white underline hover:text-purple-800 cursor-pointer"
                onClick={() => {
                  window.open(
                    "https://raydium.io/swap/?inputMint=sol&outputMint=GekTNfm84QfyP2GdAHZ5AgACBRd69aNmgA5FDhZupump",
                    "_blank"
                  );
                }}
              >
                {"Buy Orbit (GRIFT) tokens here"}
              </div>
              <div className="flex p-1 justify-center items-center bg-purple-800 text-sm font-bold text-white text-center">
                {
                  "🎉 Our first program, the LP Specialist, will help you optimize liquidity positions with real-time insights and automation, saving you time and maximizing returns (Details Soon!). 🎉"
                }
              </div>
              <Header
                chatStarted={messages?.length > 0}
                showBalances={showBalances}
                setShowBalances={setShowBalances}
              />

              {/* Outlet for main content, taking up remaining space */}
              <div
                onClick={() => setShowBalances(false)}
                className="flex flex-1"
              >
                <Outlet />
              </div>
            </div>
          </div>
        </ChatProvider>
      </WalletContextProviders>
    </PreferencesProvider>
  );
}

export default App;
