import { useEffect, useState } from "react";
import {
  InfoIcon,
  SlippageIcon,
  SwapRoute,
  ClockIcon,
  OrderIcon,
} from "../../../assets/icons/flowbite-icons";
import { useAppDispatch } from "../../../redux/hooks";
import { Message, Submit, useChat } from "../../../hooks/use-chat";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { updateTokenConfetti } from "../../../redux/slices/user";
import {
  AssembledTransaction,
  getWalletTypeFromTransaction,
  useWallets,
  WalletType,
} from "../../../hooks/wallets/use-wallets";
import { serverApi } from "../../../services/server";
import { AlertDialog } from "../../themed/alert-dialog";
import { images } from "../../../assets/images";
import { Card, CardContent } from "../../ui/card";
import { Button } from "../../ui/button";
import { Wallet } from "lucide-react";
import { TokenCard } from "./token-card";

interface MesonMessage {
  chainId: number;
  hash_to_sign: string;
  encodedSwap: string;
}

interface QuoteResponse {
  from_token: {
    chainId: number;
    address: string;
    decimals: number;
    symbol: string;
    name: string;
    logoURI: string;
  };
  from_token_usd?: string;
  to_token: {
    chainId: number;
    address: string;
    decimals: number;
    symbol: string;
    name: string;
    logoURI: string;
  };
  to_token_usd?: string;
  from_amount: number;
  from_address: string;
  to_address: string;
  to_amount: number;
  from_chain: string;
  to_chain: string;
  estimated_time: number;
  gas_cost?: string;
  provider_fee?: string;
  slippage?: string;
  tool?: string;
  order?: string;
  agent: string;
  transactions: AssembledTransaction[];
  message_to_sign?: MesonMessage[];
  protocol_name?: string;
  bridge_id?: string; // Some Bridges Have and ID we can use to track the transaction on their explorer
}

const routePriorityTooltipContent = `
- RECOMMENDED: Balances cost and complexity. Sorts by cost, then ranks top 5% by ease of use.<br />
- FASTEST: Prioritizes shortest execution time for quickest transaction completion.<br />
- CHEAPEST: Minimizes transaction cost (token or USD minus gas cost) for the most economical option.<br />
- SAFEST: Emphasizes safety and reliability of routes, ranking by tool safety level and speed.
`;

const NATIVE_TOKEN_ADDRESSES = [
  "0x0000000000000000000000000000000000000000",
  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
];

export function BridgeQuote({
  data,
  submit,
  onDone,
  sendMessageHidden,
}: {
  data: QuoteResponse;
  submit: (data: Submit) => void;
  sendMessageHidden: (message: string) => void;
  active: boolean;
  onDone: () => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}) {
  const dispatch = useAppDispatch();
  const [bridgeCompleted, setBridgeCompleted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [explorerData, setExplorerData] = useState<{ [key: string]: string }>(
    {}
  );
  const { signTransactions, addresses, agentsPreference, signTypedMessage } =
    useWallets();
  const { allChains, appendMessage, loading } = useChat();

  const fetchExplorerData = async () => {
    try {
      const fromExplorer = await serverApi.getChainExplorerUrl(data.from_chain);
      const toExplorer = await serverApi.getChainExplorerUrl(data.to_chain);

      setExplorerData({
        fromTokenExplorer: fromExplorer,
        toTokenExplorer: toExplorer,
      });
    } catch (e) {
      console.error("Failed to fetch explorer data", e);
    }
  };

  useEffect(() => {
    fetchExplorerData();
  }, [data]);

  // For Meson Bridge it's a signature, not a sign transaction
  const sendSignature = async (message: MesonMessage) => {
    try {
      const signature = (await signTypedMessage(
        WalletType.EVM,
        message.hash_to_sign
      )) as string;
      sendMessageHidden(
        `
        Use ${data.protocol_name} to continue bridge sending this params:
        Signature: ${signature}. - Encoded Swap: ${message.encodedSwap} - Wallet Address: ${addresses.EVM}`
      );
      return signature;
    } catch (e: any) {
      if (e.message === "User rejected the signature request") {
        setError("Signature request was rejected by the user");
      } else {
        setError("An unexpected error occurred during signature request");
      }
    }
  };

  function onBridge() {
    signTransactions(data.transactions).then(async (res) => {
      if (res.error) {
        if (res.error === "switching network") {
          onBridge();
          return;
        }
        setError("Error: " + res.error);
        return;
      } else if (res.hashes.length !== data.transactions.length) {
        setError("Error: not all transactions were signed");
        return;
      }

      // If there is a message to sign, sign it
      // Meson Bridge for example can have transactions (approve) and a message to sign
      let messageSignature = null;
      if (data.message_to_sign) {
        messageSignature = await sendSignature(data.message_to_sign[0]);
        if (!messageSignature) {
          return;
        }
      }
      // avoid component to be reused
      setBridgeCompleted(true);
      onDone();

      // submit the transaction to the server for analytics
      // if there was a message to sign, we don't submit the transaction yet
      // For Meson we still need to send that signature via API to complete the bridge
      if (!data.message_to_sign) {
        const walletType = getWalletTypeFromTransaction(data.transactions[0]);
        const walletAddress = addresses[walletType];
        if (!walletAddress) {
          setError("Error: Wallet not found");
          return;
        }

        submit({
          transaction: data,
          signature: res.hashes[res.hashes.length - 1],
          walletAddress: walletAddress,
          protocol_name: data.protocol_name,
          bridge_id: data.bridge_id ?? "",
        });

        dispatch(
          updateTokenConfetti(data.to_token.logoURI || images.SphereLogo)
        );
      }
    });
  }

  return (
    <Card className="bg-background border shadow-sm rounded-lg mt-4 flex flex-col items-center gap-4 max-w-[550px] mb-6">
      <div className="bg-gradient-to-t from-primary/5 to-primary/25 border-b border-secondary rounded-t-lg p-3 px-4 flex flex-col sm:flex-row justify-between items-center gap-4 w-full">
        {/* From Token */}
        <TokenCard
          type="From"
          token={data.from_token}
          chain={data.from_chain}
          amount={formatDecimals(data.from_amount, 6)}
          usdValue={Number(data.from_token_usd).toFixed(2) || 0}
          chainImage={
            allChains.find(
              (chain: { name: string; image: string }) =>
                chain.name.toUpperCase() === data.from_chain.toUpperCase()
            )?.image
          }
          defaultImage={images.DefaultCryptoLogo}
        />

        {/* Swap Icon */}
        <div className="flex items-center justify-center sm:rotate-90 rotate-0">
          <svg
            className="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
            />
          </svg>
        </div>

        {/* To Token */}
        <TokenCard
          type="To"
          token={data.to_token}
          chain={data.to_chain}
          amount={formatDecimals(data.to_amount, 6)}
          usdValue={Number(data.to_token_usd).toFixed(2) || 0}
          chainImage={
            allChains.find(
              (chain: { name: string; image: string }) =>
                chain.name.toUpperCase() === data.to_chain.toUpperCase()
            )?.image
          }
          defaultImage={images.DefaultCryptoLogo}
        />
      </div>

      <CardContent className="flex w-full p-4 pt-0 flex-col sm:gap-4 gap-2">
        <div className="flex w-full flex-row">
          <span className="flex text-gray-400 font-semibold text-xs leading-6">
            AI Agent:
          </span>
          <span className="text-gray-400 text-xs leading-6 ml-2">
            {data.agent}
          </span>
        </div>

        {/* Other details */}
        {data.provider_fee && (
          <div className="flex flex-row items-center w-full">
            <InfoIcon />
            <span className="font-semibold text-xs sm:text-sm leading-6 ml-2">
              Providers Fee:
            </span>
            <span className="text-xs sm:text-sm leading-6 ml-auto">
              {Number(data.provider_fee).toFixed(4)} USD
            </span>
          </div>
        )}

        {data.gas_cost && (
          <div className="flex flex-row items-center w-full">
            <InfoIcon />
            <span className="font-semibold text-xs sm:text-sm leading-6 ml-2">
              Gas Cost (Network fee):
            </span>
            <span className="text-xs sm:text-sm leading-6 ml-auto">
              {data.gas_cost} USD
            </span>
          </div>
        )}

        <div className="flex flex-row items-center w-full">
          <ClockIcon />
          <span className="font-semibold text-xs sm:text-sm leading-6 ml-2">
            Estimated Time:
          </span>
          <span className="text-xs sm:text-sm leading-6 ml-auto">
            {data.estimated_time.toFixed(2)} min
          </span>
        </div>

        {data.slippage && (
          <div className="flex flex-row items-center w-full">
            <SlippageIcon />
            <span className="text-gray-400 text-xs sm:text-sm leading-6 ml-2">
              Slippage Setting:
            </span>
            <span className="text-gray-400 text-xs sm:text-sm leading-6 ml-auto">
              {data.slippage} %
            </span>
          </div>
        )}

        {data.order && (
          <div className="flex flex-row items-center w-full">
            <span
              className="text-gray-400 text-xs sm:text-sm leading-6"
              data-tooltip-id="my-tooltip"
              data-tooltip-html={routePriorityTooltipContent}
            >
              <OrderIcon />
            </span>
            <Tooltip id="my-tooltip" place="top-start" />
            <span className="text-gray-400 text-xs sm:text-sm leading-6 ml-2">
              Route Priority:
            </span>
            <span className="text-gray-400 text-xs sm:text-sm ml-auto">
              {data.order}
            </span>
          </div>
        )}

        {data.tool && (
          <div className="flex flex-row justify-start items-center w-full">
            <SwapRoute />
            <span className="text-gray-400 text-xs sm:text-sm leading-6 ml-2">
              {data.tool} via
            </span>
            <span className="text-gray-400 text-xs sm:text-sm leading-6 ml-2">
              {data.agent}
            </span>
          </div>
        )}
        {!NATIVE_TOKEN_ADDRESSES.includes(data.from_token.address) && (
          <div className="flex flex-row items-center w-full">
            <span className="text-gray-400  text-xs sm:text-sm leading-6 ml-2">
              From Token Address:
            </span>
            <span className="text-gray-400 text-sm leading-6 ml-auto border px-2 rounded-lg hover:bg-gray-500/20 cursor-pointer transition-all duration-300">
              <a
                href={`${explorerData.fromTokenExplorer}/token/${data.from_token.address}`}
                target="_blank"
                rel="noreferrer"
              >
                {shortenAddress(data.from_token.address)}
              </a>
            </span>
          </div>
        )}

        {!NATIVE_TOKEN_ADDRESSES.includes(data.to_token.address) && (
          <div className="flex flex-row items-center w-full">
            <span className="text-gray-400 text-sm leading-6 ml-2">
              To Token Address:
            </span>
            <span className="text-gray-400 text-sm leading-6 ml-auto border px-2 rounded-lg hover:bg-gray-500/20 cursor-pointer transition-all duration-300">
              <a
                href={`${explorerData.toTokenExplorer}/token/${data.to_token.address}`}
                target="_blank"
                rel="noreferrer"
              >
                {shortenAddress(data.to_token.address)}
              </a>
            </span>
          </div>
        )}

        {data.from_address.toLowerCase() !== data.to_address.toLowerCase() && (
          // Example Across Plus Transfer integration has diff from and to address
          <div className="flex flex-col md:flex-row items-center w-full">
            <span className="ml-2 text-xs text-left flex w-full items-center">
              <Wallet className="min-w-4 w-4 min-h-4 h-4 mr-2" />
              From Address: {shortenAddress(data.from_address)}
            </span>
          </div>
        )}

        <div className="flex flex-col md:flex-row items-center w-full">
          <span className="ml-2 text-xs text-left flex w-full items-center">
            <Wallet className="min-w-4 w-4 min-h-4 h-4 mr-2" />
            To Address: {shortenAddress(data.to_address)}
          </span>
        </div>

        {agentsPreference ? (
          <Button
            className="w-full"
            onClick={() => appendMessage("Please confirm")}
            disabled={bridgeCompleted || !agentsPreference || loading}
          >
            Confirm the action with the agent to get all executed as a batch
          </Button>
        ) : (
          <div className="text-left w-full grid grid-cols-2 gap-2">
            <Button
              variant="secondary"
              onClick={onDone}
              disabled={bridgeCompleted}
              className="dark:text-white text-black bg-secondary hover:bg-secondary/80 disabled:bg-secondary/70 w-full"
            >
              Cancel
            </Button>
            <Button onClick={onBridge} disabled={bridgeCompleted}>
              Confirm
            </Button>
          </div>
        )}

        {error && (
          <AlertDialog
            open={!!error}
            title={"Oops!"}
            description={error}
            actionLabel="Accept"
            onActionPress={() => setError("")}
          />
        )}
      </CardContent>
    </Card>
  );
}

export function shortenAddress(address: string) {
  return address.slice(0, 6) + "..." + address.slice(-6);
}

function formatDecimals(number: number, maxDecimals: number) {
  return Number.parseFloat(number.toFixed(maxDecimals)).toString();
}
