import { createContext, useContext, useEffect, useState } from "react";
import { socketInstance } from "../services/socketIo";
import { useWallets } from "./wallets/use-wallets";
import { RenderComponents } from "../components/chat/message-actions/render-component";
import { GptModel, serverApi } from "../services/server";
import { usePreferences } from "./use-preferences";
import { AgentProps } from "../components/agent-functionality";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
// import {
//   AGENT_CASUAL_MESSAGES_ARRAY,
//   AGENT_CONNECT_WALLET_MESSAGES_ARRAY,
//   AGENT_CONVERSATION_STARTER_MESSAGES_ARRAY,
//   getRandomConversationMessage,
// } from "../utils/agent-local-messages";
import { addMessageToChatHistory, setNewChatId } from "../redux/slices/user";
import { images } from "../assets/images";

export enum CreatorResponseBadges {
  CHAIN_INFO = "chain_info",
  TRANSFER_CODE_MESSAGES = "transfer_code_messages",
  DEX_CODE_MESSAGES = "dex_code_messages",
  BRIDGE_CODE_MESSAGES = "bridge_code_messages",
}

const AuthContext = createContext<any>({});

const integrator_id = "sphereone";
export const useChat = () => {
  return useContext(AuthContext);
};
export interface Message {
  agent: "ai" | "human" | "ui" | "thought";
  message: string;
  component?: string;
  data?: any;
  thoughts?: Thought[];
}

export interface Thought {
  title: string;
  content: string;
}

export interface Submit {
  transaction: any;
  signature: string; // This means the tx hash
  walletAddress: string;
  protocol_name?: string;
  chain_id?: string;
  bridge_id?: string; // Bridge Id if the protocol manages one
}

function onSocketConnection({
  loading,
  setLoading,
  setMessages,
  setSocket,
  setChatId,
  setError,
  setAvailableAgents,
  setSuperAgent,
  setAiPaymentAgent,
  setLaunchedPrograms,
  setAllChains,
  setThoughtSteps,
  dispatch,
  currentChatId,
}: any) {
  socketInstance.on("connect", () => {
    setSocket(socketInstance);
    setChatId(socketInstance.id as string);
    setLoading(false);
    serverApi.getAvailableAgents().then((r) => {
      // filter out only active agents from server
      const superAgent = r.find(
        (agent: AgentProps) => agent.name === "Super Agent" && agent.active
      );
      const aiPaymentAgent = r.find(
        (agent: AgentProps) =>
          agent.name === "AI Payment AI Agent" && agent.active
      );
      const launchedPrograms = r.filter(
        (agent: AgentProps) =>
          // We can update this with the launched agents on each iteration.
          // TODO - Maybe have this on Firebase and fetch it from there so we can easily update it.
          (agent.name === "Educational Program" ||
            agent.name === "Researcher Program") &&
          agent.active
      );
      // Sort active agents by description length
      const sortedActiveAgents = r
        .filter(
          (agent: AgentProps) =>
            agent.active &&
            agent.name !== "Super Agent" &&
            agent.name !== "AI Agent Payment Agent"
        )
        .sort((a, b) => {
          const descriptionLengthA = a.description?.length || 0;
          const descriptionLengthB = b.description?.length || 0;
          return descriptionLengthA - descriptionLengthB;
        });

      setAvailableAgents(sortedActiveAgents);

      superAgent.name = "Orbit";
      superAgent.img = images.OrbitStanding;
      superAgent.description =
        "Orbit is the top-tier program supporting all web3 operations across chains and protocols, including swaps, transfers, bridges, DeFi, fiat on/off-ramps, NFTs, scheduling, and market info.";

      // filter out only active "Super Agent" and "AI Payment Agent" from server
      setSuperAgent(superAgent);
      setAiPaymentAgent(aiPaymentAgent);
      setLaunchedPrograms(launchedPrograms);
    });
    serverApi.getChains().then((r) => setAllChains(r));
  });

  socketInstance.on("data", async (data: any) => {
    if (data?.message) {
      !loading && setLoading(true);
      const ai_message = data.message;

      setMessages((m: Message[]) => [
        ...m,
        { agent: "ai", message: ai_message },
      ]);
    } else if (data?.render_component) {
      setMessages((m: Message[]) => [
        ...m,
        {
          agent: "ui",
          message: "",
          component: data.render_component,
          data: data.render_data,
        },
      ]);
    }
    dispatch(
      addMessageToChatHistory({
        currentChatId: currentChatId ?? null,
        message: data.message ?? "",
        agent: data.render_component ? "ui" : "ai",
        data: data.render_data ?? null,
        component: data.render_component ?? null,
      })
    );
  });

  socketInstance.on("human_input", async (data) => {
    setLoading(!data.enabled);
  });

  socketInstance.on("thought", async (data: any) => {
    setThoughtSteps((prevThoughts: Thought[]) => [
      ...prevThoughts,
      {
        title: data.title || "Thinking...",
        content: data.thought,
      },
    ]);
  });

  socketInstance.on("new_chat_id_generated", async (data: any) => {
    dispatch(setNewChatId(data.chat_id));
  });

  socketInstance.on("error", async (data: any) => {
    setLoading(false);
    console.error("error", data);
    setError("Error: " + data);
  });

  socketInstance.on("disconnect", () => {
    console.log("disconnected from session");
  });
}

type chain = { image: string; name: string };
export function ChatProvider({ children }: { children: React.ReactElement }) {
  const dispatch = useAppDispatch();
  const [availableAgents, setAvailableAgents] = useState<AgentProps[]>([]);
  const [superAgent, setSuperAgent] = useState<AgentProps | undefined>(
    undefined
  );
  const [aiPaymentAgent, setAiPaymentAgent] = useState<AgentProps | undefined>(
    undefined
  );

  const [launchedPrograms, setLaunchedPrograms] = useState<AgentProps[]>([]);
  const [allChains, setAllChains] = useState<chain[]>([]);
  const [gptModels, setGptModels] = useState<GptModel[]>([]);
  const [socket, setSocket] = useState<any>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [chatId, setChatId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  // const [messages, setMessages] = useState<Message[]>([
  //   {
  //     agent: "ai",
  //     message: getRandomConversationMessage(
  //       AGENT_CONVERSATION_STARTER_MESSAGES_ARRAY
  //     ),
  //   },
  // ]);
  const [thoughtSteps, setThoughtSteps] = useState<Thought[]>([]);
  const [error, setError] = useState<string>("");
  const [transactionStatuses, setTransactionStatuses] = useState<{
    [id: number]: boolean;
  }>({});
  const { addresses, agentsPreference, authSig, coinbaseMpc } = useWallets();
  const { preferredAgent } = useAppSelector((state) => state.gpt);

  const { countryCode, currentChatId } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  // Array selector based on the step of the chat
  // 0: single connect wallet message
  // 1: casual + connect wallet message (if the user doesn't connect the wallet on a second attempt)
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    const updatedTransactionStatuses = { ...transactionStatuses };

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      if (
        message?.component === RenderComponents.BRIDGE ||
        message?.component === RenderComponents.TRANSFER
      ) {
        // its a transaction message
        if (updatedTransactionStatuses[i] === undefined) {
          updatedTransactionStatuses[i] = false;
        }
      }
    }

    setTransactionStatuses(updatedTransactionStatuses);
  }, [messages]);

  function completeTransaction(id: number) {
    setTransactionStatuses((s) => ({ ...s, [id]: true }));
  }
  const {
    preferences,
    updatePreferences,
    isLoaded: preferencesLoaded,
  } = usePreferences();

  useEffect(() => {
    // if (!preferencesLoaded) return;
    serverApi.getSupportedGptModels().then((models) => {
      setGptModels(models);
      updatePreferences({
        gptModel: models[0],
      });
      // const userHasValidModelSet =
      //   preferences.gptModel &&
      //   models.find(
      //     (gptModel) => gptModel.model === preferences.gptModel?.model
      //   );
      // if (!userHasValidModelSet) {
      //   updatePreferences({
      //     gptModel: models[0],
      //   });
      // }
    });
  }, [preferencesLoaded]);

  useEffect(() => {
    if (!loading && messages[messages.length - 1]?.agent === "ai") {
      setMessages((prevMessages: Message[]) => {
        const currentThoughtSteps = thoughtSteps;
        if (currentThoughtSteps.length > 0) {
          const thoughtsMessage = {
            agent: "thought",
            thoughts: currentThoughtSteps,
          };
          setThoughtSteps([]);
          return [...prevMessages, thoughtsMessage] as Message[];
        }
        return prevMessages;
      });
    }
  }, [loading]);

  useEffect(() => {
    if (chatId) return;
    if (!socket) {
      try {
        console.log("connecting to socket");
        onSocketConnection({
          loading,
          setLoading,
          setMessages,
          setSocket,
          setChatId,
          setError,
          setAvailableAgents,
          setSuperAgent,
          setAiPaymentAgent,
          setLaunchedPrograms,
          setAllChains,
          setThoughtSteps,
          dispatch,
          currentChatId,
        });
      } catch (e) {
        console.error("error", e);
      }
    }

    return () => {
      if (socket && chatId) {
        setChatId("");
        socket && socket.disconnect();
        setSocket(null);
      }
    };
  }, []);

  if (socket && submitted) {
    socket.disconnect();
  }

  async function appendMessage(
    _message?: string,
    _preferredAgent?: AgentProps
  ) {
    const message: string = _message || input;
    const selectedAgent = _preferredAgent || preferredAgent;

    if (!message) return;
    if (!addresses || Object.values(addresses).every((address) => !address)) {
      //   setMessages((m) => [...m, { agent: "human", message: message }]);
      // setLoading(true);
      // await new Promise((resolve) => setTimeout(resolve, 1000));

      // setMessages((m) => [
      //   ...m,
      //   {
      //     agent: "ai",
      //     message: getRandomConversationMessage(
      //       step === 0
      //         ? AGENT_CONNECT_WALLET_MESSAGES_ARRAY
      //         : AGENT_CASUAL_MESSAGES_ARRAY
      //     ),
      //   },
      // ]);
      // setStep(step === 0 ? 1 : 0);
      // setLoading(false);
      // setInput("");
      // return;
      return setError("Please connect your wallet to continue.");
    }
    if (chatId === "")
      return setError(
        "Chat not created yet, please wait for the spinner in the chat to finish loading."
      );

    try {
      // send message
      setMessages((m) => [...m, { agent: "human", message: message }]);
      setLoading(true);
      setInput("");
      dispatch(
        addMessageToChatHistory({
          currentChatId: currentChatId ?? null,
          message: message,
          agent: "human",
          data: null,
          component: null,
        })
      );

      socket.emit("data", {
        message: message.concat(
          " ",
          selectedAgent?.function
            ? `using the ${selectedAgent.function} agent`
            : ""
        ),
        integrator_id,
        accessToken: accessToken,
        addresses: addresses,
        mpcAddress: coinbaseMpc?.address,
        gpt_model: preferences.gptModel?.model,
        slippage: preferences.slippage,
        allowance: preferences.allowance,
        riskTolerance: preferences.riskTolerance,
        agentsPreference: agentsPreference,
        selectedAgent:
          selectedAgent?.name !== superAgent?.name
            ? selectedAgent?.name
            : undefined, // filter out the super agent name so it's not used in the prompt
        authSig: authSig,
        custom_apiKey: preferences.customApiKey,
        country_code: countryCode,
      });
    } catch (e: any) {
      console.error(e);
      setError(e.response?.data?.error ?? e.message);
      setLoading(false);
    }
  }

  const submit = async ({
    transaction,
    signature,
    walletAddress,
    protocol_name,
    bridge_id, // Bridge Id if the protocol manages one
  }: Submit) => {
    try {
      socket.emit("submit", {
        transaction,
        signature,
        walletAddress,
        protocol_name,
        bridge_id,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const resetChat = async () => {
    // setMessages([
    //   {
    //     agent: "ai",
    //     message: getRandomConversationMessage(
    //       AGENT_CONVERSATION_STARTER_MESSAGES_ARRAY
    //     ),
    //   },
    // ]);
    setMessages([]);
    setThoughtSteps([]);
    setChatId("");
    setSubmitted(false);
    // setStep(0);
    // TODO find a way to reset the chat/ connection
    // await createChat();
    window.location.reload();
  };

  // This is used to start a new chat within the same request.sid or restore an existing one
  // If 'null' is set, it's a complete new chat
  const restoreOrSetNewChat = async (
    chat_id: string | null,
    chat_history: string | null
  ) => {
    socket?.emit("set_chat_id", chat_id, chat_history);
  };

  const currentTransaction = Number(
    Object.keys(transactionStatuses)
      .sort((a: string, b: string) => Number(a) - Number(b))
      .find((key: string) => transactionStatuses[Number(key)] === false) ?? null
  );

  async function sendMessageHidden(message: string) {
    if (!message) return;
    if (!addresses || Object.values(addresses).every((address) => !address)) {
      // setLoading(true);
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      // setMessages((m) => [
      //   ...m,
      //   {
      //     agent: "ai",
      //     message: getRandomConversationMessage(
      //       step === 0
      //         ? AGENT_CONNECT_WALLET_MESSAGES_ARRAY
      //         : AGENT_CASUAL_MESSAGES_ARRAY
      //     ),
      //   },
      // ]);
      // setStep(step === 0 ? 1 : 0);
      // // setLoading(false);
      // return;
      return setError("Please connect your wallet to continue.");
    }
    if (chatId === "")
      return setError(
        "Chat not created yet, please wait for the spinner in the chat to finish loading."
      );

    try {
      // send message
      socket.emit("data", {
        message,
        integrator_id,
        accessToken: accessToken,
        addresses: addresses,
        gpt_model: preferences.gptModel?.model,
        slippage: preferences.slippage,
        riskTolerance: preferences.riskTolerance,
        allowance: preferences.allowance,
        agentsPreference: agentsPreference,
        authSig: authSig,
      });
      setLoading(true);
    } catch (e: any) {
      console.error(e);
      setError(e.response?.data?.error ?? e.message);
      setLoading(false);
    }
  }
  const value = {
    gptModels,
    messages,
    setMessages,
    thoughtSteps,
    setThoughtSteps,
    appendMessage,
    sendMessageHidden,
    currentTransaction,
    completeTransaction,
    availableAgents,
    superAgent,
    aiPaymentAgent,
    launchedPrograms,
    setLaunchedPrograms,
    submit,
    loading,
    setLoading,
    input,
    setInput,
    chatId,
    setSubmitted,
    resetChat,
    restoreOrSetNewChat,
    submitted,
    accessToken,
    setAccessToken,
    error,
    setError,
    allChains,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
