import { getDefaultDescription } from "../components/chat/chat-empty-screen";
import { useChat } from "../hooks/use-chat";
import Messages from "../components/chat/messages";
import ChatInput from "../components/chat/chat-input";
import { useEffect, useState } from "react";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { auth } from "../utils/firebase";
import { AlertDialog } from "../components/themed/alert-dialog";
import TokenConfetti from "../components/TokenConfetti";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getCountryCode } from "../redux/slices/userThunk";
import { Avatar } from "../components/ui/avatar";
import { images } from "../assets/images";
import { Button } from "../components/ui/button";
import {
  ArrowLeft,
  CalendarClockIcon,
  HandCoins,
  Landmark,
  ScaleIcon,
  Waypoints,
} from "lucide-react";
import {
  AgentProps,
  AgentSupportedActions,
} from "../components/agent-functionality";
import {
  ArrowRightEndOnRectangleIcon,
  ArrowsRightLeftIcon,
  ArrowTrendingUpIcon,
  ChartPieIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import { selectPreferredAgentThunk } from "../redux/slices/gptThunk";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "../components/ui/sidebar";
import { setCurrentChatId } from "../redux/slices/user";

export default function ExecutorGpt() {
  return <ExecutorGptView />;
}

// TODO - ONCE WE MERGE THE PR #164 (HOME PAGE CHANGES)
// WE CAN USE THESE FROM THE ACTION_CARDS_FILE THAT IT HAS
// SO WE DON'T REPEAT IT
const actionIcons: Record<AgentSupportedActions, React.ReactNode> = {
  [AgentSupportedActions.SWAP]: <ArrowsRightLeftIcon className="h-6 w-6" />,
  [AgentSupportedActions.BRIDGE]: <Waypoints className="h-6 w-6" />,
  [AgentSupportedActions.DEX]: <ChartPieIcon className="h-6 w-6" />,
  [AgentSupportedActions.PERPS]: <ArrowTrendingUpIcon className="h-6 w-6" />,
  [AgentSupportedActions.BALANCE]: <ScaleIcon className="h-6 w-6" />,
  [AgentSupportedActions.ONRAMP]: <Landmark className="h-6 w-6" />,
  [AgentSupportedActions.TRANSFER]: (
    <ArrowRightEndOnRectangleIcon className="h-6 w-6" />
  ),
  [AgentSupportedActions.NFT]: <PhotoIcon className="h-6 w-6" />,
  [AgentSupportedActions.STAKING]: <HandCoins className="h-6 w-6" />,
  [AgentSupportedActions.SCHEDULE]: <CalendarClockIcon className="h-6 w-6" />,
};

function ExecutorGptView() {
  const [tokenConfetti, setTokenConfetti] = useState<string | undefined>();
  const starters: string[] = useAppSelector((state) =>
    state.gpt.conversationStarters.slice(0, 5)
  );

  const {
    chatId,
    messages,
    setMessages,
    thoughtSteps,
    setThoughtSteps,
    loading,
    appendMessage,
    sendMessageHidden,
    input,
    setInput,
    accessToken,
    setAccessToken,
    error,
    setError,
    submit,
    setSubmitted,
    currentTransaction,
    completeTransaction,
    restoreOrSetNewChat,
  } = useChat();

  const { destinationToken } = useAppSelector((state) => state.user);
  const { preferredAgent } = useAppSelector((state) => state.gpt);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useSidebar();

  async function setSelectedAgent(agent: AgentProps | null) {
    await dispatch(selectPreferredAgentThunk(agent));
  }

  useEffect(() => {
    destinationToken && setTokenConfetti(images.OrbitStanding);
  }, [destinationToken]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAccessToken(await user.getIdToken(true));
      } else {
        setAccessToken(null);
        signInAnonymously(auth);
      }
    });
    dispatch(getCountryCode());
  }, []);

  return (
    <div className="flex flex-col h-screen w-full bg-background">
      <ErrorAlert error={error} setError={setError} />

      {/* Main container */}
      <div className="flex h-[calc(100vh-10rem)] flex-col w-full pb-14">
        {/* Main content: chat window and input */}
        <div className="flex-grow flex flex-col overflow-hidden">
          {tokenConfetti && (
            <div>
              <TokenConfetti tokenUrl={tokenConfetti} />
            </div>
          )}

          {/* Back button */}
          {messages.length === 0 && (
            <Button
              onClick={() => {
                setSelectedAgent(null);
                setInput("");
                setMessages([]);
                setThoughtSteps([]);
                setSubmitted(false);
                navigate("/programs");
              }}
              variant="ghost"
              size="icon"
              className="relative top-2 left-4"
            >
              <ArrowLeft className="h-4 w-4" />
            </Button>
          )}

          {/* Input Field */}
          {preferredAgent && messages.length === 0 ? (
            <div className="flex flex-col w-full h-full bottom-14 pb-20 mb-20 container justify-center">
              {/* Agent Info */}
              <div className="flex w-full h-full flex-col items-center justify-center">
                <div className="flex flex-col items-center text-center space-y-4 pt-4">
                  <Avatar className="h-20 w-20">
                    <img
                      src={preferredAgent.img ?? images.DefaultAgentLogo}
                      alt={preferredAgent.name}
                      className="rounded-full"
                    />
                  </Avatar>
                  <h2 className="text-2xl font-bold">{preferredAgent.name.replace("Agent", "")}</h2>
                  <p className="text-muted-foreground max-w-2xl">
                    {preferredAgent.description ||
                      getDefaultDescription(preferredAgent.action)}
                  </p>
                </div>
                {/* Example inputs */}
                <div className="flex gap-2 flex-wrap items-center justify-center overflow-y-auto mt-4 max-h-36">
                  {starters.map((s) => {
                    const actionIcon = preferredAgent.action
                      ? actionIcons[preferredAgent.action]
                      : null;
                    return (
                      <Button
                        variant="outline"
                        className="rounded-full"
                        onClick={() => {
                          setMessages([]);
                          restoreOrSetNewChat(null, null);
                          dispatch(setCurrentChatId(chatId));
                          appendMessage(s);
                        }}
                      >
                        {actionIcon}
                        <p className="font-medium text-xs sm:text-sm flex justify-center align-middle items-center">
                          {s}
                        </p>
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            // Messages
            <Messages
              completeTransaction={completeTransaction}
              currentTransaction={currentTransaction}
              submit={submit}
              accessToken={accessToken ?? ""}
              chatId={chatId}
              setSubmitted={setSubmitted}
              messages={messages}
              setMessages={setMessages}
              thoughtSteps={thoughtSteps}
              setThoughtSteps={setThoughtSteps}
              sendMessageHidden={sendMessageHidden}
              loading={loading}
            />
          )}
        </div>
      </div>

      {/* Chat Input Section */}
      <div
        className={`flex flex-col h-fit bg-background/70 backdrop-blur-sm absolute bottom-0 ring-0 transition-all duration-300 ease-in-out ${
          state === "collapsed"
            ? "w-[calc(100vw)]"
            : "w-[calc(100vw)] md:w-[calc(100vw-16rem)]"
        }`}
      >
        <div className="flex flex-col h-fit w-full mt-2 py-2 px-6 gap-y-2">
          <ChatInput
            loading={loading}
            messages={messages}
            input={input}
            setInput={setInput}
            appendMessage={appendMessage}
            onClick={() => appendMessage()}
          />
          <div>
            <p className="text-xs text-center text-muted-foreground">
              Caution: Orbit's responses may contain errors. Always verify the
              information provided.
            </p>
            <p className="text-xs text-center text-muted-foreground">
              Powered by SphereOne © 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ErrorAlert(props: {
  error: string;
  setError: (e: string) => unknown;
}) {
  return (
    <AlertDialog
      open={!!props.error}
      title={"Oops!"}
      description={props.error}
      actionLabel="Accept"
      onActionPress={() => props.setError("")}
    />
  );
}
