import { useState } from "react";
import { Card, CardContent } from "../../ui/card";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../ui/dialog";
import { ChevronRight } from "lucide-react";

interface SwapSuggestion {
  swap_action: string;
  swap_explanation: string;
}

interface MemeTradeOptionsProps {
  data: SwapSuggestion[] | { data: SwapSuggestion[] };
  sendMessageHidden: (message: string) => void;
}

export function MemeTradeOptions({
  data,
  sendMessageHidden,
}: MemeTradeOptionsProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedSwap, setSelectedSwap] = useState<SwapSuggestion | null>(null);

  const swapData = Array.isArray(data) ? data : data.data;

  const handleSwapClick = (swap: SwapSuggestion) => {
    setSelectedSwap(swap);
    setIsDialogOpen(true);
  };

  const handleConfirmSwap = () => {
    if (selectedSwap) {
      sendMessageHidden(selectedSwap.swap_action + "using Jupiter Agent");
      setIsDialogOpen(false);
    }
  };

  return (
    <Card className="w-full bg-background text-foreground mt-4">
      <CardContent className="px-4 py-2">
        <h2 className="text-xl font-bold text-center mb-4">
          Suggested Meme Swaps
        </h2>
        <div className="space-y-4">
          {swapData.map((swap, index) => (
            <Card key={index} className="bg-secondary">
              <CardContent className="p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <div className="space-y-2 flex-grow">
                  <h3 className="font-medium text-sm sm:text-base">
                    {swap.swap_action}
                  </h3>
                  <p className="text-xs text-muted-foreground">
                    {swap.swap_explanation}
                  </p>
                </div>
                <Button
                  onClick={() => handleSwapClick(swap)}
                  className="w-full sm:w-24"
                >
                  Swap
                  <ChevronRight className="ml-2 h-4 w-4" />
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
        <p className="mt-4 mb-2 text-xs text-muted-foreground text-left">
          Meme Trade Suggestions
        </p>
        <p className="mb-2 text-xs text-muted-foreground text-left">
          Do your own research before making any trades.
        </p>
      </CardContent>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader
          >
            <DialogTitle className="text-center">Confirm Swap</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-sm">{selectedSwap?.swap_action}</p>
            <p className="text-xs text-muted-foreground mt-2">
              {selectedSwap?.swap_explanation}
            </p>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleConfirmSwap}>Confirm Swap</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
